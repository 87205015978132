import { Component, Input, EventEmitter, Output } from '@angular/core';
import { take } from 'rxjs/operators';

import { NotificationService, EBSService, AssignmentService, AssignmentsCountersService } from '@shared/services';
import { TableHeaderEntryModel, TableBodyEntryModel } from '@shared/models';
import { AssignmentExtended } from '@shared/factories';
import { messages } from '@messages';

@Component({
  selector: 'ebs-table',
  templateUrl: '../../table-entries.component.html',
  styleUrls: ['./ebs-table.component.sass'],
})
export class EbsTableComponent {
  @Input() rows:         AssignmentExtended[];
  @Input() paginationId: string;

  @Output() headerCallback = new EventEmitter<any>();
  @Output() updateData     = new EventEmitter<any>();
  constructor(
    private assignmentsCountersService: AssignmentsCountersService,
    private assignmentService:          AssignmentService,
    private ebsService:                 EBSService,
    private notificationService:        NotificationService
  ) { }

  headerCallbackHandler(res) {
    this.headerCallback.emit(res);
  }

  getTableClass(): string {
    return 'ebs-list-wrapper';
  }

  getOptionalValue(field: string): boolean {
    return this[field] || null;
  }

  prepareTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: 'Mitarbeiter', class: 'name-cell',     sort_by: ['external_name']   },
      { title: 'Einspiel ID', class: 'date-cell',     sort_by: ['ebs_id']          },
      { title: 'Pers. Nr.',   class: 'pers-num-cell', sort_by: ['personal_number'] },
      { title: 'Einsätze',    class: 'title-cell',    sort_by: ['title']           },
      { title: 'Anruf',       class: 'action-cell'                                 }
    ];
  }

  prepareTableBody(): TableBodyEntryModel[] {
    return this.rows.map((assignment: AssignmentExtended): TableBodyEntryModel => ({
      cells: [
        { xs_label: 'Mitarbeiter', class: 'name-cell',     value: assignment.external_employee.nameReverse },
        { xs_label: 'Pers. Nr.',   class: 'pers-num-cell', value: assignment.personal_number               },
        { xs_label: 'Einspiel ID', class: 'date-cell',     value: assignment.latestEBS.id                  },
        { xs_label: 'Einsätze',    class: 'title-cell',    children: [{ 
          class: 'ebs-history-wrapper', children: [
            { class: 'am-flex-column',   children: [
              { class: 'history-item', children: [
                { class: assignment.latestEBS.confirmed_at ? 'icon-tick' : 'icon-cross' },
                { class: `history-item-name ${assignment.confirmed ? '' : 'needs-confirmation'}`, routerLink: ['/time-tracking/ebs-details', assignment.id, assignment.latestEBS.id], value: `${assignment.title} v${assignment.latestEBS.version}` },
              ]},
              { class:'history-container', skip: !assignment.history.length, children: assignment.history.map(e => ({
                  class: 'history-item old', children: [ 
                    { class: e.confirmed_at ? 'icon-tick' : 'icon-cross' },
                    { class: `history-item-name ${e.confirmed ? '' : 'needs-confirmation'}`, routerLink: ['/time-tracking/ebs-details', assignment.id, e.id], value: `${assignment.title} v${e.version}` }
                ]
              }))}
            ]},
            { class: `icon-arrow-down ${assignment.history.length ? '' : 'hide'}`, click: (event) => this.collapseHistory(event) }
          ]},
        ]},
        { xs_label: ' ', class: 'action-cell',
          buttons: [
            {
              class: 'font-small-icon tooltip-hover blue-thin-border',
              icon: `${assignment.latestEBS.archived_at ? 'icon-rotate-ccw' : 'icon-archive'} am-flex-center`,
              tooltip: assignment.latestEBS.archived_at ? 'Wiederherstellen' : 'Archivieren',
              click: () => assignment.latestEBS.archived_at ? this.manualUnArchive(assignment) : this.manualArchive(assignment)
            },
            {
              class: 'action-cell-button',
              label: 'Anrufen',
              icon: 'icon-phone',
              click: () => this.callExternal(assignment.external_employee.phone_number)
            }
          ]
        }
      ]
    }));
  }

  private collapseHistory(e): void {
    let historyWrapper = e.target.previousSibling.getElementsByClassName('history-container')[0];
    if (historyWrapper.classList.contains('collapsed')) historyWrapper.style.height = '0px';
    else {
      let dd = `${Array.from(historyWrapper.children).map((el: any) => el.offsetHeight).reduce((sum, val) => sum += val + 5, 0)}px`;
      historyWrapper.style.height = dd;
    }
    e.target.classList.toggle('collapsed');
    historyWrapper.classList.toggle('collapsed');
  }

  private callExternal(num: string): void {
    let tel;
    if (num) {
      if (num.charAt(0) === '0') tel = `+49${num.substring(1)}`;
      else if (num.substring(0,2) === '49' && num.length === 12) tel = `+${num}`;
      else tel = `+49${num}`;
    } else return this.notificationService.alert(messages.errors.validation.phoneNumberNotExist);

    let link = `tel:${tel}`;
    window.open(link, '_system');
  }

  private manualArchive(assignment: AssignmentExtended): void {
    this.notificationService.confirm('Bestätigung', 'Möchten Sie diese EBS Version und alle vorherigen archivieren ?', () => {
      this.notificationService.wait();
      this.ebsService.archiveEBS(assignment.latestEBS.id).pipe(take(1)).subscribe(
        res   => this.refreshEBSData(),
        error => this.notificationService.alert(error.message || error)
      )
    });
  }

  private manualUnArchive(assignment: AssignmentExtended): void {
    this.notificationService.confirm('Bestätigung', 'Möchten Sie diese EBS Version wiederherstellen ?', () => {
      this.notificationService.wait();
      this.ebsService.unArchiveEBS(assignment.latestEBS.id).pipe(take(1)).subscribe(
        res   => this.refreshEBSData(),
        error => this.notificationService.alert(error.message || error)
      )
    });
  }

  private refreshEBSData(): void {
    if (this.paginationId === 'archive') this.updateData.emit();
    else this.assignmentService.forceReload();
    this.assignmentsCountersService.reloadEBSCounter();
  }

}
