import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'comment-box',
  templateUrl: 'comment-box.component.html',
  styleUrls: ['./comment-box.component.sass'],
  host: {'class': 'am-flex-column gap15'}
})
export class CommentBoxComponent {
  @Input() value: string;
  @Input() buttonClass: string;
  @Input() collapseNote: boolean;
  @Input() readOnly: boolean;

  @Output() saveCallback = new EventEmitter<any>();
  @Output() updateTempValue = new EventEmitter<any>();
  @Output() boxCallback = new EventEmitter<any>();
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.collapseNote && (changes.collapseNote.firstChange || changes.collapseNote.currentValue !== this.collapseNote)) {
      this.collapseNote = changes.collapseNote.currentValue;
    }
  }

  openCommentBox(): void {
    this.boxCallback.emit(true);
    setTimeout(() => {
      let el = document.getElementById('internal-comment');
      el.focus();
    });
  }

  saveComment(): void {
   this.saveCallback.emit(this.value);
  }

  updateValue(e): void {
    this.updateTempValue.emit(e.target.value);
  }

  closeCommentBox(): void {
    this.boxCallback.emit(false);
  }

}
