// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-items: center;
}

input[type=number] {
  width: 50px;
  padding: 0;
}

input[type=text] {
  width: 100%;
}

.icon-edit {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  color: #0099A8;
  cursor: pointer;
}

.hide-pencil {
  pointer-events: none;
  opacity: 0;
}

.value {
  margin-right: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
