export const messages = {
  auth: {
    success: {
      codeWasSent: "Aktivierungscode wurde an E-Mail gesendet",
      activatedSuccessfully: 'Benutzer wurde erfolgreich aktiviert',
      canLogInNewPassword: 'Sie können sich nun mit Ihrem neuen Passwort einloggen!',
      passwordСhanged: 'Dein Passwort wurde erfolgreich geändert',
      sentValidationEmail: 'Wir haben Ihnen eine Validierungs-E-Mail geschickt. Bitte prüfen Sie Ihren Posteingang.',
      pinSentToSms: 'PIN wurde per SMS gesendet.',
    },
    error: {
      enterEmailToActivate: 'Um Ihr Benutzerkonto zu aktivieren, geben Sie bitte Ihre E-Mail Adresse ein.',
      enterEmailToResetPassword: 'Um Ihr Passwort zurückzusetzen, geben Sie bitte Ihre E-Mail Adresse ein.',
      passwordsNotMatch: 'Passwörter stimmen nicht überein, bitte richtig eingeben.',
    }
  },
  assignments: {
    extend: {
      success: { assignmentExtendedSuccessfully:  'Ihre Anfrage für die Verlängerung des Einsatzes war erfolgreich.'          },
      error:   { dateOfAssignmentMustNotBeInPast: 'Das Datum der Einsatzverlängerung darf nicht in der Vergangenheit liegen.' }
    },
    export: {
      note:  { noTimeFramesSelected:'Es wurden keine Zeitrahmen ausgewählt' },
      error: { selectDate: 'Bitte wählen Sie ein Datum.'                    }
    }
  },
  new_ar: {
    success: { 
      activityRecordSaved: 'Vielen Dank! Der Tätigkeitsnachweis wurde gespeichert.',
      activityRecordMonthSplitSaved: 'Tätigkeitsnachweis erfolgreich erstellt. Der TN wurde wegen des Monatswechsels automatisch in 2 Belege unterteilt.',
      activityRecordMonthSplitReleased: 'Tätigkeitsnachweis erfolgreich freigegeben. Der TN wurde wegen des Monatswechsels automatisch in 2 Belege unterteilt.'
    },
    error: { assignmentDontHaveAssignedEBS:'Dieser Einsatz hat noch keinen zugeteilten EBS. Daher kann aktuell kein TN erstellt werden.' }
  },
  activity_reports: {
    details: {
      success: {
        thanksActivityChecked: 'Vielen Dank! Der Tätigkeitsnachweis wurde geprüft.',
        thanksActivityDeleted: 'Vielen Dank! Der Tätigkeitsnachweis wurde gelöscht.',
        thanksActivityReleased: 'Vielen Dank! Der Tätigkeitsnachweis wurde freigegeben.',
        thanksActivityRejected: 'Vielen Dank! Der Tätigkeitsnachweis wurde abgelehnt.',
        thanksActivityCheckedAndCompleted: 'Vielen Dank! Der Tätigkeitsnachweis wurde überprüft und erledigt.',
        reportWasSent: (emails) => `Ihr TN-Beleg wurde erfolgreich an ${emails} Empfänger gesendet.`
      },
      note: {
        releaseWithoutSavingComment: 'Sind Sie sicher, dass Sie diesen Tätigkeitsnachweis freigeben möchten ohne Ihren Kommentar zu speichern?',
        wantDeleteEntry: 'Möchten Sie diesen Eintrag wirklich löschen ?',
        wantDeleteEntryWithPortalReference: 'Möchten Sie diesen Tätigkeitsnachweis wirklich löschen? Bitte beachten Sie, dass somit auch die Referenz im Kundenportal gelöscht wird. Falls dieser noch nicht freigegeben ist, kann dieser auch nicht mehr freigegeben werden.'
      },
      erorr: {
        errorWhileLoadingActivityReport: 'Es ist ein Fehler beim Laden des Tätigkeitsnachweises aufgetreten. Bitte versuchen Sie es erneut.',
        errorWhileLoadingMileageReport: 'Dieser Kilometerbericht konnte nicht geladen werden.',
        dailyReportsWithinCalendarWeek: 'Die Tagesberichte müssen alle innerhalb der ausgewählten Kalenderwoche sein.',
        dailyReportsNextMonth: 'Die Tagesberichte müssen innerhalb der ausgewählten Kalenderwoche und des ausgewählten Monats liegen.',
        addWorkingHours: 'Bitte fügen Sie Ihre Arbeitszeiten oder einen Anhang hinzu.'
      }
    }
  },
  photo_documents: {
    details: {
      success: {
        documentMovedToArchive: 'Vielen Dank! Das Dokument wurde in das Archiv verschoben.',
        documentTransferredInZvooveAndMovedToArchive: (type) => `Vielen Dank! Der Datensatz wurde als ${type === 'submit_as_contact_zvoove' ? 'Kontakt' : 'Dokument'} in zvoove übertragen und ins Archiv verschoben.`
      }
    }
  },
  vacation_requests: {
    details: {
      success: {
        thanksVacationChecked: 'Vielen Dank! Der Urlaubsantrag wurde geprüft.',
      }
    }
  },
  archive: {
    note: { noEntriesWithSuchParams:'Die Suchparameter haben keine Treffer ergeben.' }
  },
  sidebar: { 
    success: { changesSaved: 'Ihre Änderungen wurden übernommen.' }
  },
  export_ar: {
    error: {
      emailAlreadyInList: 'Diese E-mail ist schon in der Liste',
      fiveEmailsLimit: 'Es sind maximal 5 Empfänger möglich.',
      noEmailSelected: 'Keine E-Mail ausgewählt',
      selectEmailSubjectType: 'Bitte wählen Sie das Thema der E-Mail.'
    }
  },
  errors: {
    basic: {
      pleaseEnterValue: 'Bitte tragen Sie einen Wert ein.'
    },
    ar_validation: {
      addWorkingHours: 'Bitte fügen Sie Ihre Arbeitszeiten oder einen Anhang hinzu.'
    },
    validation: {
      endDateCannotBeEarlierThanStartDate: 'Das Enddatum darf nicht vor dem Startdatum liegen. Bitte wählen Sie ein anderes Enddatum.',
      timesShouldNotBeIdentical: 'Arbeitszeiten dürfen nicht identisch sein.',
      emailNotValid: 'Die angegebene E-Mail-Adresse ist nicht gültig. Bitte überprüfen Sie Ihre Eingabe.',
      phoneNumberNotExist: 'Die Telefonnummer des External Mitarbeiters ist nicht vorhanden.'
    }
  },
  note: {
    browserNotSupportPushNotifications: 'Ihr Browser unterstützt keine Push-Benachrichtigungen.',
    doNotShowAnymore: 'Diese Meldung nicht mehr anzeigen.',
    noResults: 'Es wurden keine Resultate gefunden.',
    betaFeature: 'Dieses Feature steht Ihnen bald hier in der App zur Verfügung.',
    sureWantToLeaveChangesWillBeLost: 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ihre Änderungen gehen verloren.'
  }
};
