import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDate } from "@angular/common";
import * as Excel from "exceljs";
import * as FileSaver from 'file-saver';

import { TimeFrame, ActivityReportSimplified, VacationRequest } from '@shared/factories';
import { SidebarRouteExtended, TogglerModel } from '@shared/models';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  constructor( private http: HttpClient ) { }

  private getXlsxTitle(activeTabTitle: string): string {
    switch (activeTabTitle) {
      case 'Dashboard':
        return '';
      case 'TN Kundenfreigabe vorhanden':
        return '_KundenfreigabeVorhanden';
      case 'TN Klärungsbedarf':
        return '_Klärungsbedarf';
      case 'Warten auf TN Kundenfreigabe':
        return '_WartenAufKundenfreigabe';
      case 'Offene TN':
        return '_Offen';
      case 'Freigegebene TN':
        return '_Freigegeben';
      case 'Abgelehnte TN':
        return '_Abgelehnt';
      default:
        return '';
    }
  }

  exportDashbordExcel(json: any[], activeTab: SidebarRouteExtended) {
    this.exportAsExcelFile(json, `Tempton_TN${this.getXlsxTitle(activeTab.title)}`, activeTab);
  }

  async exportAsExcelFile(json: any[], excelFileName: string, activeTab: SidebarRouteExtended = null) {
    let workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet();

    let temptonColumn   = activeTab.isCustomer && (activeTab.identifier === 'archive' || activeTab.identifier === 'dashboard' ||  activeTab.identifier === 'approved');
    let internalArchive = activeTab.isInternal &&  activeTab.identifier === 'archive';

    this.prepareTable(worksheet, [30, 30, 30, 60, 30],
      [
        'Mitarbeiter',
        activeTab.isCustomer ? 'Eingang' : activeTab.identifier === 'archive' ? 'Erstellung'  : 'Eingang',
        temptonColumn ? 'Tempton' : internalArchive ? 'Überprüfung' : null,
        'Einsatz',
        'Zeitraum'
      ].filter(h => h),
      json.map(item => [
        item.external_employee.name,
        formatDate(item.created_at, 'dd.MM.yyyy', 'de'),
        temptonColumn || internalArchive ? item.approved_at || item.archived_at ? formatDate(item.approved_at || item.archived_at, 'dd.MM.yyyy', 'de') : '-' : null,
        item.assignment.title,
        `${item.calendar_week} ${item.date}`
      ].filter(i => i))
    );

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, excelFileName);
  }

  async exportTimeFrames(data: TimeFrame[], exportColumns: any[], from: Date, to: Date, timeFormat: string) {
    let workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet();
    await this.prepareHeader(workbook, worksheet);

    let date = worksheet.addRow([`Zeitraum: ${formatDate(from, 'dd.MM.yyyy', 'de')} - ${formatDate(to, 'dd.MM.yyyy', 'de')}`]);
    date.font = { name: 'Calibri', size: 11, bold: true };
    date.height = 30;
    date.alignment = { vertical: 'top', indent: 1 };
    this.styleBackgroundColor(date, 'FFFFFF');

    this.prepareTimeFramesTable(worksheet, exportColumns, [30, 80, 25, 30, 15, 20, 15, 15, 15],
      [
        'Mitarbeiter',
        'Einsätze',
        'Zeitraum',
        'Status',
        'Datum',
        'Arbeitszeit',
        'Pausen',
        'Gesamtstunden',
        'Arbeitsstunden abzgl. Pausen'
      ],
      this.prepareTimeFramesTableBody(data, timeFormat)
    );

    this.styleTableBody(worksheet);
    this.styleBoldRow(worksheet.getRow(5));

    if (!exportColumns.length ||
        !exportColumns.find(col => col &&  col.title === 'Gesamtstunden') ||
        !exportColumns.find(col => col &&  col.title === 'Arbeitsstunden abzgl. Pausen') ||
        exportColumns.find(col => col && (col.title === 'Gesamtstunden' || col.title === 'Arbeitsstunden abzgl. Pausen') && col.active)
    ) this.styleBoldRow(worksheet.lastRow);

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, `Taetigkeitsnachweise_${formatDate(from, 'ddMMyyyy', 'de')}_${formatDate(to, 'ddMMyyyy', 'de')}`);
  }

  async exportFailedERP(data: ActivityReportSimplified[], form) {
    let workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet();
    await this.prepareHeader(workbook, worksheet);

    if (form.from || form.to) {
      let date = worksheet.addRow([this.getFromToString(form)]);
      date.height = 30;
      date.alignment = { vertical: 'top', horizontal: 'left'};
      date.font = { name: 'Calibri', size: 11, bold: true };
      this.styleBackgroundColor(date, 'FFFFFF');
    }

    this.prepareTable(worksheet, [30, 15, 80, 30, 20],
      [
        'Mitarbeiter',
        'Eingang',
        'Einsatz',
        'Zeitraum',
        'Status'
      ],
      this.prepareFailedERPTableRows(data)
    );

    worksheet.eachRow((row, index) => {
      if (index > 4) {
        if (!row.alignment) row.alignment = { vertical: 'top', wrapText: true };
        if (!row.height) row.height = 30;
        this.styleBackgroundColor(row, index % 2 ? 'F2F2F2' : 'FFFFFF');
      }
    });
 
    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, `TN_Zvoove_Status`);
  }

  async exportVacationRequestsListExcel(json: VacationRequest[], toggler: TogglerModel = null) {
    let workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet();

    this.prepareTable(worksheet, [30, 30, 30, 60, 30],
      [
        'Mitarbeiter',
        'Pers. Nr.',
        'Eingang',
        'Urlaubsart',
        'Zeitraum'
      ].filter(h => h),
      json.map(item => [
        item.externalEmployee.name,
        item.externalEmployee.personal_number,
        formatDate(item.createdAt, 'dd.MM.yyyy', 'de'),
        item.leaveTypeMapped,
        `${item.calendarWeek} ${item.date}`,
      ])
    );

    let name = 'Urlaubsanträge';
    if (toggler) name += `_${toggler.label}`;

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, name);
  }

  private getFromToString(form): string {
    let string: string = 'Zeitraum:';
    if (form.from) string = string + formatDate(form.from, 'dd.MM.yyyy', 'de');
    if (form.from && !form.to) string = `${string} bis heute`;
    if (form.to && !form.from) string = `${string} alles`;
    if (form.to) string = `${string} bis ${formatDate(form.to, 'dd.MM.yyyy', 'de')}`;
    return string;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    const formatedDate = formatDate(new Date, 'dd.MM.yyyy', 'de');

    FileSaver.saveAs(data, `${fileName}_${formatedDate}.xlsx`);
  }

  private async prepareHeader(workbook, worksheet) {
    await this.addLogo(workbook, worksheet);
    let logoSpacer = worksheet.addRow();
    logoSpacer.height = 64;

    let header = worksheet.addRow(['Tempton Personaldienstleistungen GmbH']);
    header.font = { name: 'Calibri', size: 14, bold: true };
    header.height = 40;
    header.alignment = { indent: 1 };
    let subHeader = worksheet.addRow(['Schürmannstraße 24 | 45136 Essen']);
    subHeader.alignment = { vertical: 'top', horizontal: 'left', indent: 1};
    subHeader.height = 40;
    this.styleBackgroundColor(logoSpacer, 'FFFFFF');
    this.styleBackgroundColor(header, 'FFFFFF');
    this.styleBackgroundColor(subHeader, 'FFFFFF');
  }

  private async addLogo(workbook, worksheet) {
    let blob = await this.http.get('/assets/images/tempton-logo-blue.png', { responseType: 'blob' }).toPromise();
    let base64 = await this.toBase64(blob);
    const logo = workbook.addImage({
      base64: base64,
      extension: 'png',
    });
    worksheet.addImage(logo, {
      tl: { col: 0.3, row: 0.9 },
      ext: { width: 290, height: 64 }
    });
  }

  private toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  private prepareTable(worksheet, widths, headers, list) {
    worksheet.columns = headers.map((h, index) => ({ key: h, width: widths[index],  outlineLevel: 2 }))
    worksheet.addRow(headers);
    list.forEach(i => {
      let temp: any = {};
      headers.forEach((h, index) => { temp[h] = i[index]; });
      let row = worksheet.addRow(temp);
      row._cells.forEach(cell => {
        if (cell._column._key === 'Gesamtstunden') cell.value = cell.text.replace('total_with_pause_', '');
        if (cell._column._key === 'Arbeitsstunden abzgl. Pausen') cell.value = cell.text.replace('total_without_pause_', '');
      });

      if (temp['Gesamtstunden'] && temp['Gesamtstunden'].indexOf('total_with_pause_') === 0 ||
          temp['Arbeitsstunden abzgl. Pausen'] && temp['Arbeitsstunden abzgl. Pausen'].indexOf('total_without_pause_') === 0) {
        row.font = { name: 'Calibri', size: 11, bold: true };
        row.height = 20;
        row.alignment = { vertical: 'middle'};
      }
    });
  }

  private styleTableBody(worksheet): void {
    worksheet.eachRow((row, index) => {
      if (index > 4) {
        if (!row.alignment) row.alignment = { vertical: 'top', wrapText: true };
        row.alignment.horizontal = 'right';
        if (!row.height) row.height = 40;
        this.styleBackgroundColor(row, index % 2 ? 'F2F2F2' : 'FFFFFF');
      }
    });
  }

  private styleBackgroundColor(row, color: string): void {
    row.fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb: color}
    };
  }

  private styleBoldRow(row): void {
    this.styleBackgroundColor(row, 'D0D0D0');
    row.font = { name: 'Calibri', size: 11, bold: true };
  }

  private prepareFailedERPTableRows(data): any[] {
    return data.map(tf => [
      tf.external_employee.name,
      formatDate(tf.approved_at, 'dd.MM.yyyy', 'de'),
      tf.assignment.title,
      tf.calendar_week + ' ' + tf.date,
      tf.export_state === 'failed_export' ? 'Fehlerhaft' : 'Manuell erledigt'
    ]);
  }

  private prepareTimeFramesTable(worksheet, exportColumns, ...table: [number[], string[], string[]]) {
    let widths  = table[0].filter((e, index) => !exportColumns[index + 1] || exportColumns[index + 1].active);
    let headers = table[1].filter((e, index) => !exportColumns[index + 1] || exportColumns[index + 1].active);
    let rows    = table[2].map((r: any) => r.filter((e, index) => !exportColumns[index + 1] || exportColumns[index + 1].active))
                          .filter(r => r.join('') && r.join('') !== 'Gesamt');
    this.prepareTable(worksheet, widths, headers, rows);
  }

  private prepareTimeFramesTableBody(data, timeFormat) {
    let rows = this.prepareTimeFramesTableRows(data, timeFormat);
    this.prepareTotalByWeek(data, rows, timeFormat);
    return rows;
  }

  private prepareTimeFramesTableRows(data, timeFormat): any[] {
    let strings = data.map(tf => [
      tf.external_employee.name,
      tf.assignment_title,
      `${tf.calendar_week}\n${tf.date}`,
      `${tf.working_period_approved_at ? 'TN bei Tempton überprüft' : 'TN noch in Bearbeitung (Kunde/Tempton)'}`,
      `${formatDate(tf.started_at, 'EEEE','de')}\n${formatDate(tf.started_at, 'dd.MM.yyyy','de')}`,
      `${formatDate(tf.started_at, 'HH:mm', 'de')} - ${formatDate(tf.ended_at, 'HH:mm', 'de')}`,
      `${this.formatDate(tf.pausesDuration, timeFormat).replace('.', ',')}`,
      `${this.formatDate(tf.totalDuration, timeFormat).replace('.', ',')}`,
      `${this.formatDate(tf.totalDurationExcludingPauses, timeFormat).replace('.', ',')}`
    ]);

    let list = strings.map((tf, index) => {
      if (!index) return tf;
      return tf.reduce((arr, cell, kindex) => {
        let el: string;
        if (kindex === 0) el = cell !== strings[index - 1][kindex] ? cell : '';
        else if (kindex < 3) el = arr[kindex - 1] || cell !== strings[index - 1][kindex] ? cell : '';
        else el = cell;
        arr.push(el);
        return arr;
      }, []);
    });
    
    list.splice(list.length, 0 , ['Gesamt', '', '', '', '', '', '',
      `${this.formatDate(data.reduce((sum,tf) => sum += tf.totalDuration, 0), timeFormat).replace('.', ',')}`,
      `${this.formatDate(data.reduce((sum,tf) => sum += tf.totalDurationExcludingPauses, 0), timeFormat).replace('.', ',')}`
    ]);
    return list;
  }

  private addReport(tf, wp, index) {
    let temp = {...wp};
    return ({
      index: index,
      totalDuration: (temp.totalDuration ? +temp.totalDuration : 0) + +tf.totalDuration,
      totalDurationExcludingPauses: (temp.totalDurationExcludingPauses ? +temp.totalDurationExcludingPauses : 0) + +tf.totalDurationExcludingPauses
    });
  }

  private prepareTotalByReport(data, rows, timeFormat): void {
    let totalPerReport = {};
    data.forEach((i, index) => {
      if (!totalPerReport[i.working_period_id]) totalPerReport[i.working_period_id] = {};
      totalPerReport[i.working_period_id] = this.addReport(i, totalPerReport[i.working_period_id], index);
    });

    Object.values(totalPerReport).slice().sort((a: any, b: any) => +a.index - +b.index).reverse().forEach((a: any) => {
      let temp = ['', '', '', '', '', '',
        `${this.formatDate(a.totalDuration, timeFormat).replace('.', ',')}`,
        `${this.formatDate(a.totalDurationExcludingPauses, timeFormat).replace('.', ',')}`
      ];
      rows.splice(a.index + 1, 0, temp);
    });
  }

  private prepareTotalByWeek(data, rows, timeFormat): void {
    let totalPerWeek = {};
    data.forEach((i, index) => {
      let week = i.assignment_title + '_' + i.started_at.getFullYear() + '_' + i.calendar_week.split(' ')[1];
      if (!totalPerWeek[week]) totalPerWeek[week] = {};
      totalPerWeek[week] = this.addReport(i, totalPerWeek[week], index);
    });
    Object.values(totalPerWeek).slice().sort((a: any, b: any) => +a.index - +b.index).reverse().forEach((a: any) => {
      let temp = ['', '', '', '', '', '', '',
        `total_with_pause_${this.formatDate(a.totalDuration, timeFormat).replace('.', ',')}`,
        `total_without_pause_${this.formatDate(a.totalDurationExcludingPauses, timeFormat).replace('.', ',')}`
      ];
      rows.splice(a.index + 1, 0, temp);
    });
  }

  private formatDate(date, timeFormat) {
    if (timeFormat === 'industrial') return this.formatDateIndustrial(date);
    else if (timeFormat === 'real-time') return this.formatDateRealTime(date);
  }

  private formatDateIndustrial(duration: number): string {
    if (duration < 0) return '0.0';
    let hours = Number(duration / 60 / 60 / 1000).toFixed(2);
    return +hours.substr(-1) ? hours : Number(hours).toFixed(1);
  }

  private formatDateRealTime(duration: number): string {
    if (duration < 0) return '00:00';
    const hours = Math.floor(duration / 60 / 60 / 1000);
    const minutes = Math.round((duration - (hours * 60 * 60 * 1000)) / 60 / 1000);
    return `${!hours ? '00' : hours < 10 ? '0' + hours : hours}:${!minutes ? '00' : minutes < 10 ? '0' + minutes : minutes}`;
  }

}