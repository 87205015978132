import { EmployeeModel } from '@shared/models';

export class PhotoDocumentSimplifiedModel {
  id:                        number;
  created_at:                string;

  read_at:                   string;
  state:                     PhotoDocumentStates;

  subject:                   string;
  subject_corrected:         string;

  released_to_archive_at:    string;
  released_to_zvoove_status: string;
  released_to_zvoove_at:     string;

  external_employee:         EmployeeModel;
}

export class PhotoDocumentModel extends PhotoDocumentSimplifiedModel {
  personal_number:           number;

  released_to_archive_by:    EmployeeModel;
  released_to_zvoove_by:     EmployeeModel;

  submit_to_zvoove:          boolean;

  message_external_employee: string;
  comment_internal_employee: string;

  attachment_url:            string;
  attachment_rotation_angle: number;
  days_before_auto_archive:  number;
}

export enum PhotoDocumentStates {
  unreleased = "unreleased",
  archived   = "released_to_archive",
  zvooved    = "released_to_zvoove",
  deleted    = "archived_by_system",
};
