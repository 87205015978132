import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, of, forkJoin, timer      } from 'rxjs';
import { map, switchMap, takeUntil, catchError, repeat } from 'rxjs/operators';

import { AssignmentExtended, MileageMoneyDates                           } from '@shared/factories';
import { AssignmentsResponseModel, AssignmentExtendedModel, ZipCodeModel } from '@shared/models';

import { QueryCollectorService } from '../query-collector.service';
import { SessionStorageService } from '../session-storage.service';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssignmentService {
  private get TN_ASSIGNMENTS_API(): string { return `${environment.apiUrl}time_tracking/api/v3/assignments` };

  private start$ = new Subject<void>();
  private stop$  = new Subject<void>();

  constructor(
    private http:                  HttpClient,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService
  ) { }

  forceReload(): void {
    this.forceStop();
    this.start$.next();
  }

  forceStop(): void {
    this.stop$.next();
  }

  get assignmentList(): Observable<AssignmentExtended[]> {
    return timer(0).pipe(
      switchMap(() => this.getAssignmentsList('dashboard')),
      takeUntil(this.stop$),
      repeat({ delay: () => this.start$ })
    );
  }

  getAssignmentsArchive(): Observable<AssignmentExtended[]> {
    return this.getAssignmentsList('archive');
  }

  getAssignmentsPaginated(page: number = 1, search: string = '', betaFlag: string = null): any {
    return this.requestAssignments(this.getAssignmentsListUrl('paginated', [page, search, betaFlag]));
  }

  private getAssignmentsListUrl(queryType: string, args: any = null): string {
    if      (queryType === 'dashboard') return this.queryCollectorService.getAssignmentListQuery();
    else if (queryType === 'paginated') return this.queryCollectorService.getAssignmentsPaginated(args[0], args[1], args[2]);
    else if (queryType === 'archive')   return this.queryCollectorService.getAssignmentArchiveQuery();
  }

  getAssignmentsForVacationRequest(filters: any = {}): Observable<AssignmentExtended[]> {
    return this.requestAssignments(this.queryCollectorService.getAssignmentsVacationRequestQuery(filters)).pipe(
      map((res: any) => res.assignments)
    );
  }

  private getAssignmentsList(queryType: string): Observable<AssignmentExtended[]> {
    return this.requestAssignments(this.getAssignmentsListUrl(queryType)).pipe(
      map((res: any) => {
        this.sessionStorageService.changeTotalCount(res.totalCount);
        return res.assignments;
      })
    );
  }

  private requestAssignments(query: string = ''): any {
    return this.http.get<AssignmentsResponseModel>(`${this.TN_ASSIGNMENTS_API}${query}`).pipe(
      map(res => ({
        totalPages:  res?.meta?.paging?.total_pages || 0,
        totalCount:  res?.meta?.paging?.total_count || 0,
        assignments: res?.assignments?.map(j => new AssignmentExtended(j)) || []
      }))
    );
  }

  getAllAssignments(): Observable<AssignmentExtendedModel[]> {
    return this.http.get<any>(this.TN_ASSIGNMENTS_API).pipe(
      map((res) => res.assignments)
    );
  }

  getAssignments(filters = null): Observable<AssignmentExtended[]> {
    return this.http.get<any>(`${this.TN_ASSIGNMENTS_API}${this.queryCollectorService.getAssignmentsQuery(filters)}`).pipe(
      map((res) => res.assignments),
      map(assignments => assignments.map(a => new AssignmentExtended(a)))
    );
  }

  getSingleAssignment(assignmentId: number): Observable<AssignmentExtended>  {
    return this.http.get<any>(`${this.TN_ASSIGNMENTS_API}/${assignmentId}`).pipe(
      map((res) => res.assignment),
      map((res) => new AssignmentExtended(res))
    );
  }

  getSingleAssignmentWithZipCode(assignmentId: number) {
    return forkJoin(
      this.getSingleAssignment(assignmentId),
      this.getZipCodes(assignmentId)
    ).pipe(
      map(res => ({
        assignment: res[0],
        zip_code:   res[1]
      })
    ));
  }

  getZipCodes(assignmentId): Observable<ZipCodeModel> {
    return this.http.get<any>(`${this.TN_ASSIGNMENTS_API}/${assignmentId}/zip_code`).pipe(
      catchError(err => of({})),
      map(res => res.zip_code)
    );
  }

  getAssignmentMileageData(assignmentId: number): Observable<MileageMoneyDates[]> {
    return this.http.get<any>(`${this.TN_ASSIGNMENTS_API}/${assignmentId}/mileage_money`).pipe(
      catchError(err => of({})),
      map(res => res.mileage_money.map(mm => new MileageMoneyDates(mm)))
    );
  }

}