import { Component, Input, EventEmitter, Output } from '@angular/core';
import { formatDate } from '@angular/common';
import { Params     } from '@angular/router';

import { SessionStorageService, ToasterService      } from '@shared/services';
import { TableHeaderEntryModel, TableBodyEntryModel } from '@shared/models';
import { PhotoDocument } from '@shared/factories';

@Component({
  selector:     'photo-documents-table',
  templateUrl: '../../table-entries.component.html',
  styleUrls: ['./photo-documents-table.component.sass'],
})
export class PhotoDocumentsTableComponent {
  @Input() rows:         PhotoDocument[];
  @Input() paginationId: string;

  @Output() headerCallback = new EventEmitter<any>();
  constructor(
    private toasterService:        ToasterService,
    private sessionStorageService: SessionStorageService
  ) { }

  headerCallbackHandler(res) {
    this.headerCallback.emit(res);
  }

  getTableClass(): string {
    return 'pd-list-wrapper';
  }

  getOptionalValue(field: string): boolean {
    return this[field] || null;
  }

  prepareTableHeader(): TableHeaderEntryModel[] {
    if      (this.paginationId === 'pd-list') return this.preparePhotoDocumetsListTableHeader();
    else if (this.paginationId === 'archive') return this.preparePhotoDocumetsArchiveTableHeader();
  }

  private preparePhotoDocumetsTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: 'Mitarbeiter', class: 'name-cell',     sort_by: ['last_name']                                                          },
      { title: 'Pers. Nr.',   class: 'pers-num-cell', sort_by: ['personal_number']                                                    },
      { title: 'Dokumenttyp', class: 'subject-cell',  sort_by: ['subject_corrected_or_subject']                                       },

      { title: 'Status',      class: 'status-cell',   sort_by: [this.paginationId === 'pd-list' ? 'read_at' : 'released_to_zvoove_at'] },
      { title: 'Aktion',      class: 'action-cell'                                                                                    }
    ];
  }

  private preparePhotoDocumetsListTableHeader(): TableHeaderEntryModel[] {
    let cols = this.preparePhotoDocumetsTableHeader();
    cols.splice(1, 0, { title: 'Erstellt', class: 'date-cell', sort_by: ['created_at'] });
    return cols;
  }

  private preparePhotoDocumetsArchiveTableHeader(): TableHeaderEntryModel[] {
    let cols = this.preparePhotoDocumetsTableHeader();
    cols.splice(1, 0, { title: 'Eingang',                class: 'date-cell',    sort_by: ['created_at']             });
    cols.splice(5, 0, { title: 'Ins Archive übertragen', class: 'archive-cell', sort_by: ['released_to_archive_at'] });
    cols.splice(6, 0, { title: 'An Zvoove Übertragen',   class: 'zvoove-cell',  sort_by: ['released_to_zvoove_at']  });
    return cols;
  }

  prepareTableBody(): TableBodyEntryModel[] {
    if      (this.paginationId === 'pd-list') return this.preparePhotoDocumentsListTableBody();
    else if (this.paginationId === 'archive') return this.preparePhotoDocumentsArchiveTableBody();
  }

  private preparePhotoDocumentsArchiveTableBody(): TableBodyEntryModel[] {
    let rows = this.preparePhotoDocumentsTableBody();
    this.rows.forEach((pd, index) => {
      rows[index].cells.splice(1, 0, { xs_label: 'Eingang',                class: 'date-cell',    value: formatDate(pd.createdAt,             'dd.MM.yyyy HH:mm', 'de') });
      rows[index].cells.splice(5, 0, { xs_label: 'Ins Archive übertragen', class: 'archive-cell', value: formatDate(pd.releasedToArchiveAt, 'dd.MM.yyyy',       'de') });
      rows[index].cells.splice(6, 0, { xs_label: 'An Zvoove Übertragen',   class: `zvoove-cell ${pd.releasedToZvooveAt ? '' : 'align-center-zvoove' }`, value: pd.releasedToZvooveAt ? formatDate(pd.releasedToZvooveAt,'dd.MM.yyyy', 'de') : '----' });
    });
    return rows;
  }

  private preparePhotoDocumentsListTableBody(): TableBodyEntryModel[] {
    let rows = this.preparePhotoDocumentsTableBody();
    this.rows.forEach((pd, index) => {
      rows[index].cells.splice(1, 0, { xs_label: 'Erstellt', class: 'date-cell', value: formatDate(pd.createdAt,'dd.MM.yyyy HH:mm', 'de') });
    });
    return rows;
  }

  private preparePhotoDocumentsTableBody(): TableBodyEntryModel[] {
    return this.rows.map((pd: PhotoDocument): TableBodyEntryModel => ({
      bold: !pd.readAt,
      cells: [
        { xs_label: 'Mitarbeiter', class: 'name-cell',     value: pd.nameReverse    },
        { xs_label: 'Pers. Nr.',   class: 'pers-num-cell', value: pd.personalNumber },
        { xs_label: 'Dokumenttyp', class: 'subject-cell',  value: pd.activeSubject  },

        { class: 'status-cell xs-hide', children: [
          { class: `status-icon ${this.getStatusPDColor(pd)}`},
          { class: `web-tooltip left short ${this.getStatusPDColor(pd)}`, children: [{
              class: 'tooltip-title', value: this.getStatusText(pd)
            }]
          }]
        },
        { xs_label: '', class: 'action-cell',
          xs_icons: [{ class: `status-icon ${this.getStatusPDColor(pd)}`, click: () => this.toasterService.pdStatusToast(this.getStatusText(pd), this.getStatusPDColor(pd)) }],
          buttons: [{
            class: 'action-cell-button',
            label: pd.releasedToZvooveAt ? 'Ansehen' : 'Bearbeiten',
            routerLink: ['/time-tracking/pd-details', pd.id],
            routerLinkState: this.getFastNavigationData()
          }]
        }
      ]
    }));
  }

  private getStatusPDColor(pd: PhotoDocument): string {
    if (pd.releasedToZvooveAt)  return 'blue';
    if (pd.releasedToArchiveAt) return 'green';
    if (pd.readAt)              return 'orange';
    if (!pd.readAt)             return 'red';
  }

  private getStatusText(pd: PhotoDocument): string {
    if (pd.releasedToZvooveAt)  return 'An zvoove übertragen';
    if (pd.releasedToArchiveAt) return 'Archiviert';
    if (pd.readAt)              return 'Gelesen, nicht an zvoove übertragen';
    if (!pd.readAt)             return 'Ungelesen';
  }

  private getFastNavigationData(): Params {
    return ({
      data: this.rows.map(r => ({
        id: r.id,
        page: this.sessionStorageService.pageNumberValue
      }))
    });
  }

}
