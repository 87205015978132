import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MileageMoneyReportsResponseModel, MileageMoneyReportSubmitModel } from '@shared/models';
import { MileageMoneyReportVersion                                       } from '@shared/factories';
import { environment } from 'environments/environment';

@Injectable()
export class MileageMoneyService {
  private get TN_MILEAGE_MONEY_API(): string { return `${environment.apiUrl}time_tracking/api/v3/mileage_money_reports` };
  constructor (private http: HttpClient) { }

  getWorkingPeriodMileageMoneyVersions(mileageMoneyId: number): Observable<MileageMoneyReportVersion[]> {
    return this.http.get<MileageMoneyReportsResponseModel>(`${this.TN_MILEAGE_MONEY_API}/${mileageMoneyId}/versions`).pipe(
      map(res => res.mileage_money_report_versions.map(mmr => new MileageMoneyReportVersion(mmr)).sort((a,b) => b.created_at.getTime() - a.created_at.getTime()))
    );
  }

  saveMileageMoneyVersion(mileageMoneyId: number, data): Observable<any> {
    let body: MileageMoneyReportSubmitModel = {
      mileage_money_report: data
    };
    return this.http.put<any>(`${this.TN_MILEAGE_MONEY_API}/${mileageMoneyId}`, body).pipe(
      map(res => res)
    );
  }

}
