import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Params     } from '@angular/router';
import { formatDate } from '@angular/common';
import { take       } from 'rxjs/operators';

import { VacationRequest } from '@shared/factories';
import { FailedErpService, HelperService, NotificationService, SessionStorageService, VacationRequestsCountersService, VacationRequestService } from '@shared/services';
import { TableHeaderEntryModel, TableBodyEntryModel, TableBodyCellNestedEntryModel, TableListIconModel } from '@shared/models';

@Component({
  selector:     'vacation-requests-table',
  styleUrls: ['./vacation-requests-table.component.sass'],
  templateUrl: '../../table-entries.component.html',
})
export class VacationRequestsTableComponent {
  @Input() rows:         VacationRequest[];
  @Input() paginationId: string;
  @Input() isInternal:   boolean;
  @Input() isCustomer:   boolean;

  @Output() headerCallback = new EventEmitter<any>();
  constructor(
    private failedErpService:                FailedErpService,
    private vacationRequestService:          VacationRequestService,
    private vacationRequestsCountersService: VacationRequestsCountersService,
    private helperService:                   HelperService,
    private sessionStorageService:           SessionStorageService,
    private notificationService:             NotificationService,
  ) { }

  headerCallbackHandler(res) {
    this.headerCallback.emit(res);
  }

  getTableClass(): string {
    if (this.paginationId === 'vr-details') return 'vr-details-wrapper';
    else return 'vr-list-wrapper';
  }

  getOptionalValue(field: string): boolean {
    return this[field] || null;
  }

  prepareTableHeader(): TableHeaderEntryModel[] {
    if      (this.paginationId === 'vr-list')    return this.prepareVacationRequestsListTableHeader();
    else if (this.paginationId === 'archive')    return this.prepareVacationRequestsArchiveTableHeader();
    else if (this.paginationId === 'failed-erp') return this.prepareVacationRequestsListTableHeader();
    else if (this.paginationId === 'vr-details') return this.prepareVacationRequestDetailsTableHeader();
  }

  private prepareVacationRequestsTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: 'Mitarbeiter', class: 'name-cell',     sort_by: ['external_employee_last_name']       },
      { title: 'Pers. Nr.',   class: 'pers-num-cell', sort_by: ['external_employee_personal_number'] },
      { title: 'Urlaubsart',  class: 'type-cell',     sort_by: ['leave_type_priority']               },
      { title: 'Zeitraum',    class: 'period-cell',   sort_by: ['starts_on']                         },
      { title: 'Zusatzinfo',  class: 'am-flex-center icons-cell relative', pageOnly: ['dashboard', 'archive'], tooltipHtml: this.getExtraInfoTooltip(),
        sortOptions: [
          { class: 'am-flex-center font-small-icon icon-tick',    sort_by: ['prechecked_by_internal_at', 'created_at'], skip: this.isCustomer },
          { class: 'am-flex-center font-small-icon icon-holiday', sort_by: ['holiday',                   'created_at'], active: true          },
          { class: 'am-flex-center font-small-icon icon-tempton', sort_by: ['creator',                   'created_at'], skip: this.isCustomer }
        ]
      },
      { title: 'Aktion',      class: `action-cell ${this.paginationId === 'failed-erp' ? 'failed-erp' : ''}` }
    ];
  }

  private prepareVacationRequestsListTableHeader(): TableHeaderEntryModel[] {
    let cols = this.prepareVacationRequestsTableHeader();
    cols.splice(1, 0, { title: 'Eingang', class: 'date-cell',   sort_by: ['created_at']                                                                          });
    cols.splice(5, 0, { title: 'Status',  class: 'status-cell', sort_by: [this.isInternal ? 'internal_state_priority' : 'customer_state_priority', 'created_at'] });
    return cols;
  }

  private prepareVacationRequestsArchiveTableHeader(): TableHeaderEntryModel[] {
    let cols = this.prepareVacationRequestsTableHeader();
    cols.splice(1, 0, { title: 'Erstellung',  class: 'date-cell',     sort_by: ['created_at']           });
    cols.splice(2, 0, { title: 'Überprüfung', class: 'reviewed-cell', sort_by: ['internal_reviewed_at'] });
    if (this.isInternal) cols.splice(5, 0, { title: 'Status',  class: 'status-cell', sort_by: ['internal_state_priority', 'created_at'] });
    return cols;
  }

  private prepareVacationRequestDetailsTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: 'Urlaubsart', class: 'type-cell'   },
      { title: 'Zeitraum',   class: 'period-cell' },
      { title: 'Anzahl',     class: 'total-cell'  },
    ];
  }

  prepareTableBody(): TableBodyEntryModel[] {
    if      (this.paginationId === 'vr-list')    return this.prepareVacationRequestsTableBody();
    else if (this.paginationId === 'archive')    return this.prepareVacationRequestsTableBody();
    else if (this.paginationId === 'failed-erp') return this.prepareVacationRequestsTableBody();
    else if (this.paginationId === 'vr-details') return this.prepareVacationRequestDetailsTableBody();
  }

  private getExtraInfoTooltip(): string {
    return `
      <div class="web-tooltip white medium bottom left-sm">
        <span class="tooltip-title">Folgende Parameter sind in dieser Spalte sichtbar:</span>
        <ul class="tooltip-list">
          ${this.isInternal ? '<li><span class="icon-tick"></span><span>Intern geprüft</span></li>' : ''}
          ${this.isInternal ? '<li><span class="icon-tempton"></span><span>Erstellt von SB</span></li>' : ''}
          <li><span class="icon-holiday"></span><span>Feiertag(e)</span></li>
        </ul>
      </div>
    `;
  }

  private prepareVacationRequestsTableBody(): TableBodyEntryModel[] {
    return this.rows.map((vr: VacationRequest): TableBodyEntryModel => ({
      cells: [
        { xs_label: 'Mitarbeiter', class: 'name-cell',     value: vr.nameReverse                                                                                                                                          },
        { xs_label: 'Pers. Nr.',   class: 'pers-num-cell', value: vr.externalEmployee.personal_number                                                                                                                     },
        { xs_label: 'Eingang',     class: 'date-cell',     value: formatDate(vr.createdAt,                           'dd.MM.yyyy HH:mm', 'de'), pageOnly: ['vr-list', 'failed-erp']                                       },
        { xs_label: 'Erstellung',  class: 'date-cell',     value: formatDate(vr.createdAt,                           'dd.MM.yyyy HH:mm', 'de'), pageOnly: ['archive']                                                     },
        { xs_label: 'Überprüfung', class: 'reviewed-cell', value: formatDate(vr.internalReviewedAt || vr.archivedAt, 'dd.MM.yyyy HH:mm', 'de'), pageOnly: ['archive']                                                     },
        { xs_label: 'Urlaubsart',  class: 'type-cell',     value: vr.leaveTypeMapped                                                                                                                                      },
        { xs_label: 'Zeitraum',    class: 'period-cell',   value: `${vr.calendarWeek}\n${vr.date}`                                                                                                                        },
        { xs_label: null,          class: 'icons-cell  xs-hide', children: [{ class: 'am-flex-center font-icon gap5', children: this.helperService.parceTableListIconsWeb(this.getIcons(vr)) }]                           },
        { xs_label: null,          class: 'status-cell xs-hide', children: this.helperService.parceTableListIconsWeb(this.getStatus(vr)),       pageOnly: ['vr-list', 'failed-erp', this.isInternal ? 'archive' : 'skip'] },
        { xs_label: '',            class: `action-cell ${this.paginationId === 'failed-erp' ? 'failed-erp' : ''}`, xs_icons: this.getIconsRowMobile(vr),
          buttons: [
            { class: this.paginationId === 'failed-erp' ? 'blue-thin-border custom-action-cell-button' : 'action-cell-button',
              label: this.getActionButtonName(vr),
              routerLink: ['/time-tracking/vr-details', vr.id],
              routerLinkState: this.getFastNavigationData()
            },
            {
              class: 'action-cell-button',
              label: 'Erledigen',
              skip: this.paginationId !== 'failed-erp' || vr.exportState === 'resolved_manually',
              click: () => this.manuallyResolve(vr)
            }
          ]
        }
      ].filter(c => c.pageOnly && c.pageOnly.filter(p => p).length ? c.pageOnly.filter(p => p).find(p => p === this.paginationId) : true)
    }));
  }

  private getStatus(vr: VacationRequest): TableListIconModel[] {
    return [{ show: true, icon: `status-icon ${this.getStatusDotColor(vr)}`, color: this.getStatusDotColor(vr), tooltipTexts: [this.getStatusText(vr)] }];
  }

  private getIcons(vr: VacationRequest): TableListIconModel[] {
    return [
      { show: !!vr.precheckedByInternalAt, icon: 'icon-tick',    color: 'white',         tooltipTexts: ['Intern geprüft'], internalOnly: true  },
      { show: !!vr.holidays?.length,       icon: 'icon-holiday', color: 'orange-border', tooltipTexts: vr.holidays.map(h => h.name)            },
      { show: !!vr.createdByInternal,      icon: 'icon-tempton', color: 'white',         tooltipTexts: ['Erstellt von SB'], internalOnly: true }
    ];
  }

  private getIconsRowMobile(vr: VacationRequest): TableBodyCellNestedEntryModel[] {
    let icons: any = this.getIcons(vr);
    icons.splice(0, 0, { show: true, icon: `status-icon ${this.getStatusDotColor(vr)}` });
    return this.helperService.parceTableListIconsMobile(icons);
  }

  private getStatusDotColor(vr: VacationRequest): string {
    if (this.paginationId === 'vr-list') return this.getStateDotColor(vr);
    else return this.getFailedERPDotColor(vr);
  }

  private getFailedERPDotColor(vr: VacationRequest): string {
    switch (vr.exportState) {
      case 'ready_to_export':
        return 'grey';
      case 'successful_export':
        return 'green';
      case 'failed_export':
        return 'red';
      case 'resolved_manually':
        return 'blue';
      default:
        return 'grey';
    };
  }

  private getStatusText(vr: VacationRequest): string {
    if (this.paginationId === 'vr-list') return this.mapState(vr);
    else return this.mapStateFailedErp(vr);
  }

  private mapStateFailedErp(vr: VacationRequest): string {
    switch (vr.exportState) {
      case 'ready_to_export':
        return 'Übertragung ausstehend';
      case 'successful_export':
        return 'Übertragung erfolgreich';
      case 'failed_export':
        return vr.exportComment;
      case 'resolved_manually':
        return 'Manuell erledigt';
      default:
        return 'Übertragung ausstehend';
    };
  }

  private getStateDotColor(vr: VacationRequest): string {
    switch (vr.state) {
      case 'awaiting_customer_approval':
      case 'awaiting_external_approval':
        return 'orange';
      case 'approved_by_customer':
      case 'approved_by_external':
      case 'awaiting_internal_approval':
        return 'green';
      case 'rejected_by_customer':
      case 'rejected_by_external':
        return 'red';
    };
  }

  private mapState(vr: VacationRequest): string {
    if (this.isInternal) return this.mapStateInternal(vr);
    if (this.isCustomer) return this.mapStateCustomer(vr);
  }

  private mapStateCustomer(vr: VacationRequest): string {
    switch (vr.state) {
      case 'approved_by_external':
      case 'approved_by_customer':
        return 'Freigabe vorhanden';
      case 'awaiting_external_approval':
      case 'awaiting_customer_approval':
        return 'Freigabe erwartet';
      case 'rejected_by_external':
      case 'rejected_by_customer':
        return 'Freigabe abgelehnt';
    };
  }

  private mapStateInternal(vr: VacationRequest): string {
    switch (vr.state) {
      case 'awaiting_customer_approval':
        return 'Kundenfreigabe erwartet';
      case 'approved_by_customer':
        return 'Kundenfreigabe vorhanden';
      case 'approved_by_external':
      case 'awaiting_internal_approval':
        return 'Freigabe erwartet';
      case 'rejected_by_customer':
        return 'Kunde gibt nicht frei';
      case 'awaiting_external_approval':
        return 'Mitarbeiterfreigabe erwartet';
      case 'rejected_by_external':
        return 'MA gibt nicht frei';
    };
  }

  private getActionButtonName(vr: VacationRequest): string {
    if (this.isInternal) return vr.readOnlyForInternal ? 'Ansehen' : 'Bearbeiten';
    if (this.isCustomer) return vr.readOnlyForCustomer ? 'Ansehen' : 'Bearbeiten';
  }

  private prepareVacationRequestDetailsTableBody(): TableBodyEntryModel[] {
    return this.rows.map((vr: VacationRequest): TableBodyEntryModel => ({
      cells: [
        { xs_label: 'Urlaubsart', class: 'type-cell',   value: vr.leaveTypeMapped               },
        { xs_label: 'Zeitraum',   class: 'period-cell', value: `${vr.calendarWeek}\n${vr.date}` },
        { xs_label: 'Anzahl',     class: 'total-cell',  value: `${vr.workingVacationDays} ${vr.workingVacationDays === 1 ? 'Arbeitstag' : 'Arbeitstage'}` },
      ]
    }));
  }

  private getFastNavigationData(): Params {
    return ({
      data: this.rows.map(r => ({
        id: r.id,
        page: this.sessionStorageService.pageNumberValue
      }))
    });
  }

  private manuallyResolve(vr: VacationRequest): void {
    this.notificationService.wait();
    this.vacationRequestService.manuallyResolveWorkingPeriod(vr.id).pipe(take(1)).subscribe(
      res => {
        this.notificationService.success(
          'Vielen Dank! Der Urlaubsantrag wurde überprüft und erledigt.',
          () => {
            this.failedErpService.forceReload();
            this.vacationRequestsCountersService.reloadCounters();
          }
        )
      },
      error => this.notificationService.alert(error.message || error)
    );
  }

}
