// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 450px) {
  ::ng-deep .content-header-wrapper {
    flex-direction: column;
  }
  ::ng-deep .content-header-wrapper .export-btn-group {
    width: 100%;
    flex-direction: column;
  }
  ::ng-deep .content-header-wrapper .export-btn-group .title-button {
    flex: 0 0 36px !important;
  }
  ::ng-deep .content-header-wrapper .export-btn-group .title-button .toggler-tab {
    flex: 1;
    justify-content: center;
  }
}

@media (max-width: 1150px) {
  .header-title-wrapper .secondary-title {
    display: none;
  }
}
.header-title-wrapper .title-icon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 15px;
  color: #FFFFFF;
  font-size: 24px;
}
@media (max-width: 768px) {
  .header-title-wrapper .title-icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    font-size: 22px;
  }
}
@media (max-width: 450px) {
  .header-title-wrapper .title-icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    font-size: 20px;
  }
}
.header-title-wrapper .title-icon.approved, .header-title-wrapper .title-icon.vr-approved {
  background-color: #95C11F;
}
.header-title-wrapper .title-icon.open, .header-title-wrapper .title-icon.vr-opened, .header-title-wrapper .title-icon.vr-awaiting, .header-title-wrapper .title-icon.failed_export {
  background-color: #FF9228;
}
.header-title-wrapper .title-icon.rejected, .header-title-wrapper .title-icon.vr-rejected {
  background-color: #DE440B;
}
.header-title-wrapper .title-icon.photo {
  background-color: #6D69A0;
}
.header-title-wrapper .title-icon.ebs {
  background-color: #3797bb;
}

@media (max-width: 450px) {
  .export-btn-group {
    justify-content: space-between;
  }
}
.export-btn-group .archive-form-button {
  flex: 0 0 170px;
}
.export-btn-group .export-button {
  flex: 0 0 120px;
}

.export-btn-group.ebs-filtering {
  justify-content: space-between;
}
@media (max-width: 450px) {
  .export-btn-group.ebs-filtering {
    flex-direction: column-reverse;
    height: 80px;
  }
}

.custom-button,
.multi-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 4px;
  overflow: hidden;
}

.custom-button.button-blue {
  background-color: #0099A8;
  color: #FFFFFF;
}
.custom-button.button-white {
  background-color: #FFFFFF;
  color: #7D8B92;
  border: 1px solid #CED4DA;
}
.custom-button.disabled {
  border: none;
  background-color: #F0F0F0;
  color: #BEBEBE;
  pointer-events: none;
}

.multi-button.disabled {
  pointer-events: none;
}
.multi-button.disabled > * {
  border: none;
  background-color: #F0F0F0;
  color: #BEBEBE;
}
.multi-button:not(.disabled) > *:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.multi-button:not(.disabled) > *:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.multi-button:not(.disabled) > *:not(:last-child) {
  border-right: 1px solid #CED4DA;
}
.multi-button:not(.disabled) > *.active {
  background-color: #0099A8;
  color: #FFFFFF;
  border-top: 1px solid #0099A8;
  border-bottom: 1px solid #0099A8;
}
.multi-button:not(.disabled) > *.active:first-child {
  border-left: 1px solid #0099A8;
}
.multi-button:not(.disabled) > *.active:last-child {
  border-right: 1px solid #0099A8;
}
.multi-button:not(.disabled) > *:not(.active) {
  border-top: 1px solid #CED4DA;
  border-bottom: 1px solid #CED4DA;
}
.multi-button:not(.disabled) > *:not(.active):first-child {
  border-left: 1px solid #CED4DA;
}
.multi-button:not(.disabled) > *:not(.active):last-child {
  border-right: 1px solid #CED4DA;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
