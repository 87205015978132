import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SidebarRouteExtended  } from '@shared/models';
import { SidebarStorageService } from '@shared/services';

@Component({
  selector: 'counters-cards',
  templateUrl: './counters-cards.component.html',
  styleUrls: ['./counters-cards.component.sass']
})
export class CountersCardsComponent implements OnInit {
  countersCards: SidebarRouteExtended[];
  @Input() activeTab:                    SidebarRouteExtended;
  @Input() isCustomer:                   boolean;
  @Input() isInternal:                   boolean;

  @Input() approvedReportsCount:         number;
  @Input() openActivityReportsCount:     number;
  @Input() clarificationReportsCount:    number;
  @Input() failedERPCount:               number;
  @Input() expiringSoonAssignmentsCount: number;
  @Input() activePhotoDocumentsCount:    number;
  @Input() unconfirmedEBSCount:          number;
  @Input() approvedVacationRequestsCount: number;
  @Input() awaitingVacationRequestsCount: number;
  @Input() rejectedVacationRequests:     number;
  @Input() failedVacationRequests:       number;

  @Output() cardCallback = new EventEmitter<any>();
  constructor(
    private sessionStorageService: SidebarStorageService
  ) { }

  ngOnInit(): void {
    this.countersCards = this.sessionStorageService.counters;
  }

  getCardCount(counter: string): number | string {
    switch (counter) {
      case 'approved':
        return this.approvedReportsCount;
      case 'open':
        return this.openActivityReportsCount;
      case 'rejected':
        return this.clarificationReportsCount;
      case 'failed_export':
        return this.failedERPCount || this.failedVacationRequests ? `${+this.failedERPCount}+${+this.failedVacationRequests}` : null;
      case 'ebs':
        return this.expiringSoonAssignmentsCount || this.unconfirmedEBSCount || 0;
      case 'photo':
        return this.activePhotoDocumentsCount;
      case 'vr-awaiting':
      case 'vr-approved':
        return this.approvedVacationRequestsCount+this.awaitingVacationRequestsCount+this.rejectedVacationRequests;
    }
  }

  changeDashboard(identifier: string): void {
    this.cardCallback.emit(identifier);
  }

}
