import { Component, Input, EventEmitter, Output } from '@angular/core';
import { formatDate } from '@angular/common';
import { MileageMoneyDaily } from '@shared/factories';
import { TableHeaderEntryModel, TableBodyEntryModel } from '@shared/models';

@Component({
  selector:     'activity-report-mileage-table',
  templateUrl:  '../../table-entries.component.html',
  styleUrls: ['./activity-report-mileage-table.component.sass'],
})
export class ActivityReportMileageTableComponent {
  @Input() rows:         MileageMoneyDaily[];
  @Input() index:        string;
  @Input() skipHeader:   boolean;
  @Input() paginationId: string;

  @Input() editMode: boolean;
  @Input() readOnly: boolean;
  @Input() editBox:  string;

  @Input() checkIfHoliday: Function;

  @Output() editBoxHandler     = new EventEmitter<any>();
  @Output() valueChangeHandler = new EventEmitter<any>();

  constructor( ) { }

  prepareTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: '',                 class: 'holiday-cell' },
      { title: 'Datum',            class: 'day-cell'      },
      { title: 'Von',              class: 'from-cell'     },
      { title: 'Nach',             class: 'to-cell'       },
      { title: 'Entfernung in km', class: 'total-km-cell' }
    ];
  }

  headerCallbackHandler(e) { }

  getTableClass(): string {
    return 'activity-report-mileage-table';
  }

  getOptionalValue(field: string): boolean {
    return this[field] || null;
  }

  prepareTableBody(): TableBodyEntryModel[] {
    return this.rows.map((moneyReport: MileageMoneyDaily, index): TableBodyEntryModel => ({
      deleted: moneyReport.deleted,
      cells: [
        { xs_label: null, class: 'holiday-cell xs-hide', children: [
          { class: `font-icon icon-holiday ${this.checkIfHoliday(moneyReport.started_at) ? '' : 'hide'}`, children: [
            { class: 'web-tooltip orange-border short right', skip: !this.checkIfHoliday(moneyReport.started_at), children: [
              { class: 'tooltip-title', value: this.checkIfHoliday(moneyReport.started_at) }
            ]}
          ]}
        ]},
        { xs_label: 'Datum', class: 'day-cell', children: [
          { class: 'am-flex-column am-flex-tablet-row am-flex-tablet-align gap5', children: [
            { value: formatDate(moneyReport.started_at, 'EEEE',       'de') },
            { value: formatDate(moneyReport.started_at, 'dd.MM.yyyy', 'de') }
          ]}
        ]},
        { xs_label: 'Von', class: 'from-cell', editableInput: {
          index: ['2', index, this.index].join(''),
          editBox: this.editBox,
          type: 'text',
          value: moneyReport.external_employee_address,
          showEdit: !this.readOnly && this.editMode,
          toggleEditBox: (val) => this.editBoxHandler.emit(val),
          onValueChange: (value) => this.updateValue(value, index, 'external_employee_address')
        }},
        { xs_label: 'Nach', class: 'to-cell', editableInput: {
          index: ['1', index, this.index].join(''),
          editBox: this.editBox,
          type: 'text',
          value: moneyReport.assignment_address,
          showEdit: !this.readOnly && this.editMode,
          toggleEditBox: (val) => this.editBoxHandler.emit(val),
          onValueChange: (value) => this.updateValue(value, index, 'assignment_address')
        }},
        { xs_label: 'Entfernung in km', class: 'total-km-cell', editableInput: {
          index: ['3', index, this.index].join(''),
          editBox: this.editBox,
          type: 'number',
          maxValue: 1000,
          value: moneyReport.total_amount_of_km,
          valueType:'distance',
          postValue: 'km',
          showEdit: !this.readOnly && this.editMode,
          toggleEditBox: (val) => this.editBoxHandler.emit(val),
          onValueChange: (value) => this.updateValue(value, index, 'total_amount_of_km')
        }}
      ]
    }));
  }

  private updateValue(value: any, index: number, field: string): void {
    let path = ['report_days', index, field];
    this.valueChangeHandler.emit({value, path});
  }

}
