import { CustomerNotificationModel, CustomerNotificationEmailRecipientModel } from "@shared/models";

export class CustomerNotification {
  created_at:       Date;
  customers_emails: string[];
  recipients:       CustomerNotificationEmailRecipientModel[];
  sender:           string;
  email_template:   string;
  constructor(data: CustomerNotificationModel) {
    this.created_at       = this.parceDate(data.created_at);
    this.customers_emails = data.customers_emails;
    this.recipients       = data.email_meta.recipients;
    this.sender           = data.email_meta.sender.full_name;
    this.email_template   = data.email_template;
  }

  toJSON(): CustomerNotificationModel {
    return {
      created_at:       this.created_at ? this.created_at.toISOString() : null,
      customers_emails: this.customers_emails,
      email_template:   this.email_template,
      email_meta: {
        recipients: this.recipients,
        sender: {
          full_name: this.sender
        },
      }
    };
  }

  private parceDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

}
