import { Injectable, Component } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { NotificationService } from '@shared/services';
import { ValidationError, TechnicalError } from '@shared/models';
import { NewActivityReportComponent, WorkingPeriodDetailsComponent } from 'app/components/time-tracking';

@Injectable()
export class UnsavedGuard  {
  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {}

  canDeactivate(target, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) {
    if (nextState.url.includes('/auth/') || nextState.url.includes('/wp-km/') || target.skipGuard) return true;
    else if (currentState.url === '/time-tracking/new-activity-report') return this.checkNewARRouteChange(target, nextState);
    else if (currentState.url.includes('/time-tracking/wp-details/')) return this.checkARDetailsRouteChange(target, nextState);
    return false;
  }

  checkNewARRouteChange(target, nextState): boolean {
    if (target.activityReport) {
      if (target.activityReport.time_frames.length && !+localStorage.getItem('skipConfirmationForNewUnsavedAR')) {
        this.notificationService.optionalNote(
          'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ihre Änderungen gehen verloren.',
          'Diese Meldung nicht mehr anzeigen.',
          () => localStorage.setItem('skipConfirmationForNewUnsavedAR', `${+!+localStorage.getItem('skipConfirmationForNewUnsavedAR')}`),
          () => {
            target.activityReport = null;
            let url = nextState.url.split(";");
            this.router.navigate([url[0], this.parceRouteParams(url.slice(1))]);
          }
        );
        return false;
      }
    }
    return true;
  }

  checkARDetailsRouteChange(target, nextState): boolean {
    let skipUnsavedPopup = +localStorage.getItem('skipConfirmationForUnsavedData');

    if (!skipUnsavedPopup) {
      let changes;
      if (target.tempMileageMoney && target.selectedVersion) changes = JSON.stringify(target.tempMileageMoney.toJSON()) !== JSON.stringify(target.selectedVersion.toJSON());
      else if (target.workingPeriod && target.originWorkingPeriod) {
        if (!target.workingPeriod.approved_at && !target.workingPeriod.archived_at) changes = JSON.stringify(target.workingPeriod.toJSON()) !== JSON.stringify(target.originWorkingPeriod.toJSON());
        if (!changes) changes = (target.originInternalNotes || '') !== (target.workingPeriod.internal_employee_notes || '');
      }
      if (changes) {
        this.notificationService.optionalNote(
          'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ihre Änderungen gehen verloren.',
          'Diese Meldung nicht mehr anzeigen.',
          () => localStorage.setItem('skipConfirmationForUnsavedData', `${+!+localStorage.getItem('skipConfirmationForUnsavedData')}`),
          () => {
            target.workingPeriod = null;
            let url = nextState.url.split(";");
            this.router.navigate([url[0], this.parceRouteParams(url.slice(1))]);
          }
        );
        return false;
      }
    }
    return true;
  }

  parceRouteParams(url: string[]) {
    let routeParams = {};
    if (url && url.length) {
      for (let i = 0; i < url.length; i++) {
        let param = url[i].split("=");
        routeParams[param[0]] = param[1];
      }
    }
    return routeParams;
  }

}
