import { EnvironmentInjector, inject, Injectable, runInInjectionContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, timer                   } from 'rxjs';
import { map, switchMap, takeUntil, tap, take, repeat } from 'rxjs/operators';

import { PhotoDocument, PhotoDocumentSimplified } from '@shared/factories';

import {
  QueryCollectorService,
  SessionStorageService,
  PhotoDocumentsCountersService
} from '@shared/services';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PhotoDocumentService {
  private start$ = new Subject<void>();
  private stop$  = new Subject<void>();

  private get PD_API():           string { return `${environment.apiUrl}time_tracking/api/v3/photo_documents` };
  private get PD_DASHBOARD_API(): string { return `${this.PD_API}/dashboard`                                  };
  private get PD_ARCHIVE_API():   string { return `${this.PD_API}/archive`                                    };

  constructor (
    private http:                  HttpClient,
    private injector:              EnvironmentInjector,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService
  ) { }

  forceReload(): void {
    this.forceStop();
    this.start$.next();
  }

  forceStop(): void {
    this.stop$.next();
  }

  get photoDocuments(): Observable<PhotoDocumentSimplified[]> {
    return timer(0).pipe(
      switchMap(() => this.requestPhotoDocuments('dashboard')),
      takeUntil(this.stop$),
      repeat({ delay: () => this.start$ })
    );
  }

  getPhotoDocuments(pdType: string): Observable<PhotoDocumentSimplified[]> {
    return this.requestPhotoDocuments(pdType);
  }

  getPhotoDocumentsArchive(): Observable<PhotoDocumentSimplified[]> {
    return this.requestPhotoDocuments('archive');
  }

  private requestPhotoDocuments(pdType: string): Observable<PhotoDocumentSimplified[]> {
    return this.http.get<any>(this.getPhotoDocumentsUrl(pdType))
    .pipe(
      tap(res => {
        this.sessionStorageService.changeTotalCount(res.meta.paging.total_count);
        this.sessionStorageService.changeTotalPages(res.meta.paging.total_pages);
      }),
      map(res => res.photo_documents.map(item => new PhotoDocumentSimplified(item)))
    );
  }

  private getPhotoDocumentsUrl(wpType: string): string {
    if      (wpType === 'dashboard') return this.PD_DASHBOARD_API + this.queryCollectorService.getPhotoDocumentsQuery();
    else if (wpType === 'archive')   return this.PD_ARCHIVE_API   + this.queryCollectorService.getPhotoDocumentsArchiveQuery();
  }

  getPhotoDocumentById(id: number): Observable<PhotoDocument> {
    return this.http.get<any>(`${this.PD_API}/${id}`)
    .pipe(
      map(res => res.photo_document),
      map(res => new PhotoDocument(res)),
      tap(res => this.readPhotoDocument(res))
    );
  }

  private readPhotoDocument(pd: PhotoDocument): void {
    if (!pd.readAt) this.http.put<any>(`${this.PD_API}/${pd.id}/read`, {}).pipe(
      take(1),
      tap(() => runInInjectionContext(this.injector, () => { 
        inject(PhotoDocumentsCountersService).reloadCounters(); 
      }))
    ).subscribe();
  }

}
