import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, combineLatest, fromEvent, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { BannerModel } from '@shared/models';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannersService {
  constructor(private http: HttpClient) { }

  get banners(): Observable<any[]> {
    return this.http.get<BannerModel[]>(`${environment.apiUrl}api/v2/company_banners`)
    .pipe(switchMap(res => {
      let banners = res && res.filter(banner => banner.tag === 'leftBanner' || banner.tag === 'rightBanner');
      if (banners.length) return combineLatest(banners.map(banner => this.getBannerImage(banner)));
      else return of([]);
    }) );
  }

  private getBannerImage(banner: BannerModel): Observable<BannerModel> {
    return this.http.get<Blob>(`${environment.apiUrl}${banner.image_path}`, {responseType: 'blob' as 'json'}).pipe(
      switchMap(response => {
        let reader = new FileReader();
        if (response) reader.readAsDataURL(response);
        return fromEvent(reader, 'load').pipe(map(() => {
          banner.img = reader.result;
          return banner;
        }));
      })
    );
  }

}
