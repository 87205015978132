// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#custom-popup.select-list-popup-wrapper .custom-popup-wrapper {
  max-width: 800px;
  min-height: 550px;
  max-height: 90vh;
  height: 0;
}
#custom-popup.select-list-popup-wrapper input[type=text] {
  width: 100%;
  margin-bottom: 20px;
}
@media (max-width: 1280px) {
  #custom-popup.select-list-popup-wrapper input[type=text] {
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  #custom-popup.select-list-popup-wrapper input[type=text] {
    height: 25px;
    margin-bottom: 10px;
  }
}
@media (max-width: 450px) {
  #custom-popup.select-list-popup-wrapper input[type=text] {
    height: 25px;
    margin-bottom: 7.5px;
  }
}
#custom-popup.select-list-popup-wrapper p {
  margin: auto;
}
#custom-popup.select-list-popup-wrapper .select-list-item {
  padding: 0 10px;
  min-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}
#custom-popup.select-list-popup-wrapper .select-list-item:hover {
  opacity: 0.7;
}
#custom-popup.select-list-popup-wrapper .select-list-item.active {
  font-weight: bold;
}
#custom-popup.select-list-popup-wrapper .custom-select-pagination {
  display: flex;
  margin: 10px 20px 0 20px;
  height: 30px;
  max-width: max-content;
}
#custom-popup.select-list-popup-wrapper .custom-select-pagination .custom-select-pagination-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  padding: 0 5px;
  box-sizing: border-box;
  cursor: pointer;
  border-top: 1px solid #CED4DA;
  border-bottom: 1px solid #CED4DA;
}
#custom-popup.select-list-popup-wrapper .custom-select-pagination .custom-select-pagination-item:first-child {
  border-left: 1px solid #CED4DA;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
#custom-popup.select-list-popup-wrapper .custom-select-pagination .custom-select-pagination-item:last-child {
  border-right: 1px solid #CED4DA;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
#custom-popup.select-list-popup-wrapper .custom-select-pagination .custom-select-pagination-item:not(:last-child) {
  border-right: 1px solid #CED4DA;
}
#custom-popup.select-list-popup-wrapper .custom-select-pagination .custom-select-pagination-item.active {
  background-color: #0099A8;
  color: #FFFFFF;
}
#custom-popup.select-list-popup-wrapper .custom-select-pagination .custom-select-pagination-item.disabled {
  background-color: #F0F0F0;
  color: #CED4DA;
  pointer-events: none;
}
#custom-popup.select-list-popup-wrapper .custom-select-pagination .custom-select-pagination-item:hover {
  opacity: 0.7;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
