import { Component, Output, EventEmitter } from '@angular/core';
import { SessionStorageService } from '@shared/services';

@Component({
  selector: 'select-list-popup',
  templateUrl: './select-list-popup.component.html',
  styleUrls: [
    '../../../styles/modules/custom-popup.sass',
    './select-list-popup.component.sass']
})
export class SelectListPopup {
  entries:     any[];
  popupClass:  string;
  checkbox:    boolean;
  active:      any;

  reload:      boolean;
  searchQuery: boolean;

  page:        any;
  totalPages:  any;

  searchValue: string;
  searchTimer: ReturnType<typeof setTimeout>;

  @Output() itemCallback = new EventEmitter<any>();
  @Output() paginationCallback = new EventEmitter<any>();
  @Output() closeCallback = new EventEmitter<any>();
  private readonly COMPONENT_NAME: string = 'SelectListPopup';
  constructor( private sessionStorageService: SessionStorageService ) {
    this.searchValue = this.searchValue;
    setTimeout(() => {
      if (this.searchQuery) {
        let el = document.getElementById('select-list-search');
        el.focus();
      }
      if (this.checkbox && this.active) this.parceCheckbox();
    });
  }

  private parceCheckbox(): void {
    this.active.split('; ').forEach(el => {
      let entrie = this.entries.find(e => e.id === +el.split('_')[0]);
      if (entrie) entrie.active = true;
    });
  }

  selectItem(value): void {
    if (!this.checkbox) {
      this.itemCallback.emit(value);
      this.closePopup();
    }
  }

  pagination(page, search, index: number = null, searchQuery: boolean = null): void {
    let p = page;
    if (isNaN(page) && index !== null) {
      if (index === 1) p = Math.round(this.page / 2);
      else p = Math.round(this.page + (this.totalPages - this.page) / 2);
    }
    this.paginationCallback.emit({
      page:        p,
      search:      search,
      searchQuery: searchQuery,
      active:      this.collectCheckbox()
    });
  }

  getPaginationButtons(): number[] {
    let buttons = [];
    if (this.totalPages >= 2 && this.totalPages <= 6) {
      for (let i = 1; i <= this.totalPages; i++) { buttons.push(i); }
      return buttons;
    } else if (this.totalPages >= 7) {
      if (this.page <= 4) for (let i = 1; i <= 5; i++) { buttons.push(i); }
      else if (this.page >= 5) buttons.push(1, '...');
      if (this.page >= 5 && this.page + 4 <= this.totalPages) {
        buttons.push(this.page - 1);
        buttons.push(this.page);
        buttons.push(this.page + 1);
      }
      if (this.page < 5  && this.page + 3 <= this.totalPages ||
          this.page >= 5 && this.page + 4 <= this.totalPages) buttons.push('...', this.totalPages);
      else if (this.page >= 5 && this.page + 4 >= this.totalPages) for (let k = 4; k >=0; k--) {
        buttons.push(this.totalPages - k);
      }
    }
    return buttons;
  }

  closePopup(e = null): void {
    if (!e || !e.target.closest('.custom-popup-wrapper')) {
      this.closeCallback.emit();
      clearTimeout(this.searchTimer);
      if (this.checkbox) this.handleCheckboxs();
      this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
    }
  }

  private collectCheckbox(): any {
    let activeItems = new Set(this.entries.filter(e => e.active).map(e => `${e.id}_${e.label}`));
    if (this.active) this.active.split('; ').filter(e => !this.entries.find(l => l.id === +e.split('_')[0])).forEach(e => activeItems.add(e));
    return [...activeItems].join('; ');
  }

  private handleCheckboxs(): void {
    let newItems = this.collectCheckbox();
    this.itemCallback.emit(newItems);
  }

  changeSearchValue(query: string): void {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => this.pagination(1, query, null, true), 1000);
  }

  clearSearch(): void {
    this.searchValue = '';
    this.pagination(1, this.searchValue);
  }

}
