import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, timer                         } from 'rxjs';
import { map, switchMap, takeUntil, catchError, tap, repeat } from 'rxjs/operators';

import { VacationRequestsResponce, WorkingPeriodsResponce } from '@shared/models';
import { ActivityReportSimplified, VacationRequest        } from '@shared/factories';

import { QueryCollectorService } from './query-collector.service';
import { SessionStorageService } from './session-storage.service';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FailedErpService {
  private start$ = new Subject<void>();
  private stop$  = new Subject<void>();

  private get WP_IMPORTS_API(): string { return `${environment.apiUrl}time_tracking/api/v3/working_periods_exports/dashboard`   };
  private get VR_IMPORTS_API(): string { return `${environment.apiUrl}time_tracking/api/v3/vacation_requests_exports/dashboard` };

  constructor (
    private http:                  HttpClient,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService
  ) { }

  forceReload(): void {
    this.forceStop();
    this.start$.next();
  }

  forceStop(): void {
    this.stop$.next();
  }

  get failedERP(): Observable<ActivityReportSimplified[] | VacationRequest[]> {
    return timer(0).pipe(
      switchMap(() => this.requestFailedERP()),
      takeUntil(this.stop$),
      repeat({ delay: () => this.start$ })
    );
  }

  private requestFailedERP(): Observable<ActivityReportSimplified[] | VacationRequest[]> {
    let toggler = this.sessionStorageService.headerTogglersValue.failed_erp.find(toggler => toggler.active);
    return this.http.get<WorkingPeriodsResponce | VacationRequestsResponce>(this.getRequestUrl(toggler.value)).pipe(
      tap(res => {
        this.sessionStorageService.changeTotalCount(res?.meta?.total_count || res?.meta?.paging?.total_count || 0);
        this.sessionStorageService.changeTotalPages(res?.meta?.total_pages || res?.meta?.paging?.total_pages || 0);
      }),
      map(res => {
        if ((res as WorkingPeriodsResponce).working_periods)     return (res as WorkingPeriodsResponce).working_periods.map(item => new ActivityReportSimplified(item));
        if ((res as VacationRequestsResponce).vacation_requests) return (res as VacationRequestsResponce).vacation_requests.map(item => new VacationRequest(item));
        return [];
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  private getRequestUrl(erpType: string): string {
    if (erpType === 'ar') return this.WP_IMPORTS_API + this.queryCollectorService.getFailedERPsQuery();
    if (erpType === 'vr') return this.VR_IMPORTS_API + this.queryCollectorService.getFailedERPsQuery('vacation_requests');
  }

}
