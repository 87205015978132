import { ContractExtensionModel } from "@shared/models";
import { Customer               } from "@shared/factories";

export class ContractExtension {
  id:                      number;
  assignment_id:           number;
  contract_extension_till: Date;
  created_at:              Date;
  customer:                Customer;
  constructor(data: ContractExtensionModel) {
    this.id                      = data.id;
    this.assignment_id           = data.assignment_id;
    this.contract_extension_till = this.parceDate(data.contract_extension_till);
    this.created_at              = this.parceDate(data.created_at);
    this.customer                = new Customer(data.customer);
  }

  private parceDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }
}
