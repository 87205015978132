import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PhotoDocumentUpdateService {
  private get PD_API(): string { return `${environment.apiUrl}time_tracking/api/v3/photo_documents_review` };
  constructor (private http: HttpClient) { }

  movePhotoDocumentToArchive(id: number, data: any): Observable<any> {
    return this.updatePhotoDocument(id, data, 'archive');
  }

  movePhotoDocumentToZvoove(id: number, data: any): Observable<any> {
    return this.updatePhotoDocument(id, data, 'zvoove');
  }

  private updatePhotoDocument(id: number, data: any, action: string = ''): Observable<any> {
    return this.http.put<any>(`${this.PD_API}/${id}/${action}`, data)
    .pipe(
      map((res) => {
        if (res.errors) throw new Error(res.errors);
      })
    );
  }

}
