import { MileageMoneyReport, ExternalEmployee } from "@shared/factories";
import { MileageMoneyReportVersionModel       } from "@shared/models";

export class MileageMoneyReportVersion {
  created_at:            Date;
  version:               number;
  event:                 string;
  mileage_money_report:  MileageMoneyReport;

  updated_by:            ExternalEmployee;
  updated_by_user_group: string;
  constructor(data: MileageMoneyReportVersionModel) {
    this.created_at            = this.parceDate(data.created_at);
    this.version               = data.version;
    this.event                 = data.event;
    this.mileage_money_report  = new MileageMoneyReport(data.mileage_money_report);

    this.updated_by            = data.updated_by ? new ExternalEmployee(data.updated_by) : null;
    this.updated_by_user_group = data.updated_by_user_group;
  }

  toJSON(): MileageMoneyReportVersionModel {
    return ({
      created_at:            this.created_at            ? this.created_at.toISOString()      : null,
      version:               this.version !== undefined ? this.version                       : null,
      event:                 this.event   !== undefined ? this.event                         : null,
      mileage_money_report:  this.mileage_money_report  ? this.mileage_money_report.toJSON() : null,

      updated_by:            this.updated_by            ? this.updated_by.toJSON()           : null,
      updated_by_user_group: this.updated_by_user_group ? this.updated_by_user_group         : null
    });
  }

  private parceDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }
}
