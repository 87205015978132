import * as $ from 'jquery';
import { CommonModule, LocationStrategy, DatePipe, PathLocationStrategy } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router  } from '@angular/router';

import { AppRoutingModule     } from './app-routing.module';
import { AuthenticationModule } from './components/authentication/authentication.module';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { AppComponent } from './app.component';
import { ComponentLoaderComponent } from './components/dynamic/component-loader/component-loader.component';

import { environment } from '../environments/environment';

import { DYNAMIC_ENVIRONMENT } from 'ngx-http-env';
import { AuthModule, AuthInterceptor, AuthConfig, AuthService } from '@tempton/ngx-msal';

import {
  NotificationComponent,
  ToastComponent,
  SidebarComponent,
  SidebarUserPreferencesTogglerComponent,
  SpinnerComponent,
  SidebarItemComponent,
  ApplicationSwitcherComponent
} from '@shared/components';

import {
  UserService,
  NotificationService,
  ToasterService,
  ValidationService,
  LoggingService,
  SessionStorageService,
  QueryCollectorService,
  ActivityReportsCountersService,
  AssignmentsCountersService,
  PhotoDocumentsCountersService,
  VacationRequestsCountersService,
  BannersService,
  SidebarStorageService,
  ActivityReportsService,
  HolidaysService,
  PhotoDocumentService,
  AssignmentService,
  AutoReloadService,
  InvoicesService,
  InvoicesCountersService,
  DocumentTypesService,
  AppAccessService,
  ReleasesService,
  FailedErpService,
  CountersService,
  InfoStatesCollectorService
} from '@shared/services';

import {
  DefaultHeadersInterceptor,
  ErrorsInterceptor,
  StopBetaCallsInterceptor
} from '@shared/helpers';

@NgModule({
  declarations: [
    AppComponent,
    BlankComponent,
    NotificationComponent,
    ToastComponent,
    ComponentLoaderComponent,
    FullComponent,
    SidebarComponent,
    SidebarItemComponent,
    SidebarUserPreferencesTogglerComponent,
    SpinnerComponent,
    ApplicationSwitcherComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    AuthModule,

    ReactiveFormsModule,

    AuthenticationModule,
  ],
  providers: [
    {
      provide: AuthConfig,
      useFactory: (): AuthConfig => environment.temptonNgxMsal,
      deps: [DYNAMIC_ENVIRONMENT],
    },
    DatePipe,
    InvoicesService,
    ReleasesService,
    ActivityReportsService,
    CountersService,
    InvoicesCountersService,
    ActivityReportsCountersService,
    AssignmentsCountersService,
    PhotoDocumentsCountersService,
    VacationRequestsCountersService,
    BannersService,
    LoggingService,
    SidebarStorageService,
    NotificationService,
    SessionStorageService,
    QueryCollectorService,
    UserService,
    ToasterService,
    ValidationService,
    HolidaysService,
    DocumentTypesService,
    PhotoDocumentService,
    AssignmentService,
    AutoReloadService,
    AppAccessService,
    FailedErpService,
    InfoStatesCollectorService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor,           multi: true, deps: [AuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor,         multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: StopBetaCallsInterceptor,  multi: true },
    { provide: LocationStrategy,  useClass: PathLocationStrategy }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // [mfa] Redirect user from /#/... to /... to continue supporting urls for hash based routing
    if(window.location.href.match('/\/#\//'))
      window.location.href = window.location.href.replace(/\/#\//, '/')
  }
}
