import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject     } from 'rxjs';
import { map, repeat, switchMap, tap } from 'rxjs/operators';

import { SidebarStorageService } from '../sidebar-storage.service';
import { QueryCollectorService } from '../query-collector.service';
import { CountersService } from './counters.service';

import { environment } from 'environments/environment';

@Injectable()
export class PhotoDocumentsCountersService {
  private get TN_PHOTO_DOCUMENTS_COUNTERS_API(): string { return `${environment.apiUrl}time_tracking/api/v3/photo_documents_counters` };

  private reloadCounter$ = new Subject<void>();
  constructor (
    private http:                  HttpClient,
    private sidebarStorageService: SidebarStorageService,
    private countersService:       CountersService,
    private queryCollectorService: QueryCollectorService,
  ) { }

  reloadCounters(): void {
    this.reloadCounter$.next();
  }

  get photoDocumentsCounters(): Observable<any> {
    return this.requestPhotoDocumentsCounters().pipe(
      repeat({ delay: () => this.reloadCounter$ })
    );
  }

  private requestPhotoDocumentsCounters(): Observable<any> {
    return of(this.TN_PHOTO_DOCUMENTS_COUNTERS_API).pipe(
      map(url => url + this.queryCollectorService.getCountersQuery()),
      switchMap(url => this.http.get<any>(url)),
      tap(({ unread }) => {
        this.sidebarStorageService.changeActivePhotoDocumentsCount(unread.length);
        this.countersService.checkForUpdates({ unread }, 'photoDocuments');
      })
    );
  }

}
