import { formatDate } from '@angular/common';
import {
  ActivityReportBasicModel,
  ActivityReportBasicWithTimeFramesModel,
  ActivityReportForSubmitModel,
  ActivityReportSimplifiedModel,
  ActivityReportExtendedModel,
  DailyReportModel,
  CustomerNotificationModel,
  ZipCodeModel,
  RecognizedTimeFrameModel,
  RecognizedActivityReportModel
} from '@shared/models';

import {
  Assignment,
  CustomerNotification,
  DailyReport,
  ExternalEmployee,
  Employee,
  Customer,
  InternalEmployee,
  Weeks,
  Holiday
} from '@shared/factories';

export class ActivityReportBasic {
  id:                number;
  start_date:        Date;
  end_date:          Date;

  status:            number;

  calendar_week:     string;
  date:              string;

  mileage_money:     boolean;

  assignment:        Assignment;
  external_employee: ExternalEmployee;
  constructor(data: any) {
    this.id                = data.id || null;
    this.start_date        = this.parceDate(this.parceDate(data.start_date).setHours(0,0,0,0));
    this.end_date          = this.parceDate(this.parceDate(data.end_date).setHours(23,59,59));

    this.status            = data.status !== undefined ? +data.status : null;
    this.calendar_week     = `KW ${Weeks.getWeekNumber(this.start_date) > 52 ? '01': Weeks.getWeekNumber(this.start_date)}`;  // double check (check consistancy week selection)
    this.date              = `${formatDate(this.start_date, 'dd.MM.yyyy','de')} - ${formatDate(this.end_date, 'dd.MM.yyyy', 'de')}`;

    this.mileage_money     = data.mileage_money !== undefined ? data.mileage_money : null;

    this.assignment        = this.checkIfInstance(data.assignment, Assignment);
    this.external_employee = this.checkIfInstance(data.external_employee, ExternalEmployee);
  }

  toJSON(): ActivityReportBasicModel {
    return {
      id:                this.id                          ? this.id                         : null,
      start_date:        this.start_date                  ? this.start_date.toISOString()   : null,
      end_date:          this.end_date                    ? this.end_date.toISOString()     : null,
      status:            this.status                      ? this.status                     : null,
      assignment:        this.assignment                  ? this.assignment.toJSON()        : null,
      external_employee: this.external_employee           ? this.external_employee.toJSON() : null,
      mileage_money:     this.mileage_money !== undefined ? this.mileage_money              : null
    };
  }

  parceDate(date: Date | string | number): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  checkIfInstance(value: any, instance: any): any {
    return value ? value instanceof instance ? value : this.newInstance(value, instance) : null;
  }

  newInstance(value: any, instance: any): any {
    return new instance(value);
  }

}

export class ActivityReportBasicWithTimeFrames extends ActivityReportBasic {
  original_time_frames: DailyReportModel[];
  time_frames:          DailyReport[];
  state_iso:            string;
  constructor(data: any, zip_code?: ZipCodeModel) {
    super(data);
    this.original_time_frames =  this.arrayNotEmpty(data.original_time_frames) || [];
    this.time_frames          = (this.arrayNotEmpty(data.original_time_frames) || []).map(tf => new DailyReport(tf));
    this.state_iso = (data as ActivityReportBasicWithTimeFrames).state_iso || zip_code && zip_code.state_iso || null;
  }

  get holidaysList(): Holiday[] {
    let hol = [];
    this.activeTimeFrames.filter(tf => tf.holidays.length).forEach(tf => {
      hol = [...hol, ...tf.holidays];
    });
    return hol;
  }

  get activeTimeFrames(): DailyReport[] {
    return this.time_frames.filter(tf => !tf.deleted && !tf.placeholder);
  }

  get arTimeWithoutPauses(): number {
    return this.activeTimeFrames.reduce((sum: number, val: DailyReport) => {
      return +sum + +val.totalDurationExcludingPauses;
    }, 0);
  }

  get arTimeWithPauses(): number {
    return this.activeTimeFrames.reduce((sum: number, val: DailyReport) => {
      return +sum + +val.totalDuration;
    }, 0);
  }

  toJSON(): ActivityReportBasicWithTimeFramesModel {
    return Object.assign(super.toJSON(), {
      original_time_frames: this.arrayNotEmpty(this.original_time_frames) ? this.original_time_frames : [],
    });
  }

  toSubmitJSON(): ActivityReportForSubmitModel {
    return {
      submitted_at:          (new Date).toISOString(),
      start_date:            formatDate(this.start_date, 'yyyy-MM-dd','de'),
      end_date:              formatDate(this.end_date,   'yyyy-MM-dd','de'),
      external_employee_id:  this.external_employee.id,
      assignment_id:         this.assignment.id,
      status:                2,
      submit_with_timestamp: 1,
      mileage_money:         this.mileage_money !== undefined ? this.mileage_money : null,
      time_frames:           this.activeTimeFrames.map(tf => tf.toSubmitJSON())
    };
  }

  arrayNotEmpty(array: any[]): any[] {
    return array?.length ? array : null;
  }

}

export class ActivityReportExtended extends ActivityReportBasicWithTimeFrames {
  created_at:                  Date;
  created_by:                  Employee;

  external_employee_signature: string;
  customer_signature:          string;
  signer:                      string;

  attachment_url:              string;
  attachment_rotation_angle:   number;
  submit_with_timestamp:       number;

  mileage_money_report_id:     number;

  external_employee_notes:     string;
  internal_employee_notes:     string;
  customer_notes:              string;

  customer_review:             string;
  customer_reviewed_at:        Date;
  customer_released_at:        Date;
  customer_rejected_at:        Date;
  reviewed_by_customer:        Customer;

  checked_time_at:             Date;
  checked_time_by:             InternalEmployee;

  approved_at:                 Date;
  approved_by:                 InternalEmployee;

  deleted_by:                  Employee;
  archived_at:                 Date;
  daysBeforeAutoArchive:       number;
  autoArchiveAt:               Date;

  life_state:                  string;
  export_state:                string;
  export_status:               number;
  export_comment:              string;

  split_child_id:              number;
  split_parent_id:             number;

  original_end_date:           Date;
  belongsToInternalLocations:  boolean;

  time_frames:                 DailyReport[];
  corrected_time_frames:       DailyReportModel[];

  customer_notifications:      CustomerNotification[];
  state_iso:                   any;

  failedErpReport:             boolean;  

  recognizedTimeFrames:        RecognizedTimeFrame[];
  validRecognizedTimeFrames:   RecognizedTimeFrame[];

  ocrMetaTable:                RecognizedTimeFrameMeta[];
  ocrImageWidth:               number;
  ocrImageHeight:              number;
  ocrUsed:                     boolean;
  constructor(data: ActivityReportExtendedModel | ActivityReportExtended, notifications?: CustomerNotificationModel[], zip_code?: ZipCodeModel, recognized_working_period?: RecognizedActivityReportModel) {
    super(data);
    this.created_at                  = this.parceDate(data.created_at);
    this.created_by                  = super.checkIfInstance(data.created_by, Employee);

    this.external_employee_signature = data.external_employee_signature ? data.external_employee_signature : null;
    this.customer_signature          = data.customer_signature          ? data.customer_signature          : null;
    this.signer                      = data.signer                      ? data.signer                      : null;

    this.attachment_url              = data.attachment_url              ? data.attachment_url              : null;
    this.attachment_rotation_angle   = data.attachment_rotation_angle   ? data.attachment_rotation_angle   : null;
    this.submit_with_timestamp       = (data as ActivityReportExtended).submit_with_timestamp !== undefined ? (data as ActivityReportExtended).submit_with_timestamp : 1;   // check

    this.mileage_money_report_id     = data.mileage_money_report_id     ? data.mileage_money_report_id     : null;

    this.external_employee_notes     = data.external_employee_notes     ? data.external_employee_notes     : null;
    this.internal_employee_notes     = data.internal_employee_notes     ? data.internal_employee_notes     : null;
    this.customer_notes              = data.customer_notes              ? data.customer_notes              : null;

    this.customer_review             = data.customer_review;
    this.customer_reviewed_at        = this.parceDate(data.customer_reviewed_at);
    this.customer_released_at        = data.customer_review === 'released' && this.parceDate(data.customer_reviewed_at);
    this.customer_rejected_at        = data.customer_review === 'rejected' && this.parceDate(data.customer_reviewed_at);
    this.reviewed_by_customer        = data.reviewed_by_customer ? data.reviewed_by_customer instanceof Customer ? data.reviewed_by_customer : new Customer(data.reviewed_by_customer) : null;

    this.checked_time_at             = this.parceDate(data.checked_time_at);
    this.checked_time_by             = super.checkIfInstance(data.checked_time_by, InternalEmployee);

    this.approved_at                 = this.parceDate(data.approved_at);
    this.approved_by                 = super.checkIfInstance(data.approved_by, InternalEmployee);

    this.deleted_by                  = super.checkIfInstance(data.deleted_by, Employee);
    this.archived_at                 = this.parceDate(data.archived_at);
    this.daysBeforeAutoArchive       = (data as ActivityReportExtendedModel).days_before_auto_archive;
    this.autoArchiveAt               = this.parceAutoArchiveAt();

    this.life_state                  = data.life_state     ? data.life_state     : null;
    this.export_state                = data.export_state   ? data.export_state   : null;
    this.export_comment              = data.export_comment ? data.export_comment : null;
    this.export_status               = data.export_status !== undefined ? +data.export_status : null;

    this.split_child_id              = data.split_child_id;
    this.split_parent_id             = data.split_parent_id;

    this.original_end_date           = data.original_end_date ? this.parceDate(this.parceDate(data.original_end_date).setHours(23,59,59)) : null;
    this.belongsToInternalLocations  = (data as ActivityReportExtendedModel).belongs_to_internal_locations !== undefined ? (data as ActivityReportExtendedModel).belongs_to_internal_locations : null;

    this.corrected_time_frames       = super.arrayNotEmpty(data.corrected_time_frames) || [];
    this.time_frames                 = super.arrayNotEmpty((data as ActivityReportExtended).time_frames) ? super.arrayNotEmpty((data as ActivityReportExtended).time_frames).map(tf => new DailyReport(tf)) : this.collectTimeFrames();

    this.customer_notifications      = super.arrayNotEmpty(notifications) ? notifications.map(n => new CustomerNotification(n)).sort((a,b) => b.created_at.getTime() - a.created_at.getTime()) :
                                       (data as ActivityReportExtendedModel).signature_confirmation ? [new CustomerNotification((data as ActivityReportExtendedModel).signature_confirmation)] : [];
    this.state_iso                   = (data as ActivityReportExtended).state_iso || zip_code && zip_code.state_iso || null;

    this.failedErpReport             = this.belongsToInternalLocations && this.approved_at && this.id && this.export_state === 'failed_export';

    this.recognizedTimeFrames        = (super.arrayNotEmpty((data as ActivityReportExtended).recognizedTimeFrames) || recognized_working_period?.recognized_time_frames || [])
                                       .map(tf => new RecognizedTimeFrame(tf));
    this.validRecognizedTimeFrames   = this.recognizedTimeFrames?.filter(rtf => rtf.date) || [];

    this.ocrMetaTable                = this.recognizedTimeFrames.length ? [
      this.ocrPolygonsAvailable(this.recognizedTimeFrames[0], 'calendarWeek'),
      ...this.recognizedTimeFrames.reduce((sum, rtf) => {
        let temp = [
          ...sum,
          this.ocrPolygonsAvailable(rtf, 'date'),
          this.ocrPolygonsAvailable(rtf, 'startedTime'),
          this.ocrPolygonsAvailable(rtf, 'endedTime'),
          this.ocrPolygonsAvailable(rtf, 'pause'),
          this.ocrPolygonsAvailable(rtf, 'worked'),
        ];
        return temp;
      }, [])
    ].filter(val => val?.polygon) : null;

    let imageSize = this.recognizedTimeFrames?.length ? this.recognizedTimeFrames[0].imageSize : null;
    this.ocrImageWidth  = imageSize ? imageSize[0] : null;
    this.ocrImageHeight = imageSize ? imageSize[1] : null;

    this.ocrUsed = (data as ActivityReportExtended).ocrUsed || !!recognized_working_period?.internal_used_at;
  }

  private ocrPolygonsAvailable(rtf: RecognizedTimeFrame, metaField: string): RecognizedTimeFrameMeta {
    return rtf[metaField+'Meta'].polygon ? Object.assign(rtf[metaField+'Meta']) : null;
  }

  private collectTimeFrames(): DailyReport[] {
    const time_frames = this.original_time_frames.map(tf => {
      if (this.corrected_time_frames.length) {
        const update = this.corrected_time_frames.find(c_tf => c_tf.original_time_frame_id === tf.id);
        if (update) return new DailyReport(tf, update);
        else return new DailyReport(tf, null, false, true);
      } else return new DailyReport(tf);
    }).filter(tf => tf);
    const newTimeFrames: DailyReport[] = this.corrected_time_frames.filter(c_tf => !c_tf.original_time_frame_id).map(c_tf => new DailyReport(c_tf, null, true));
    return [...time_frames, ...newTimeFrames];
  }

  get activeTimeFrames(): DailyReport[] {
    return this.time_frames.filter(tf => !tf.deleted);
  }

  private parceAutoArchiveAt(): Date {
    if (this.daysBeforeAutoArchive === null) return null;
    if (isNaN(this.daysBeforeAutoArchive))   return null;
    let date = new Date();
    date.setDate(date.getDate() + this.daysBeforeAutoArchive);
    date.setHours(0,0,0);
    return date;
  }

  get ocrPreselected(): boolean { return !!this.activeTimeFrames.find(tf => tf.ocrPreselected); }

  get archivedBySystem():  boolean { return this.life_state && this.life_state === 'archived_by_system';  }
  get deletedByInternal(): boolean { return this.life_state && this.life_state === 'deleted_by_internal'; }
  get deletedByCustomer(): boolean { return this.life_state && this.life_state === 'deleted_by_customer'; }

  toJSON(): ActivityReportExtendedModel {
    return Object.assign(super.toJSON(), {
      created_at:                    this.created_at                               ? this.created_at.toISOString()                      : null,
      created_by:                    this.created_by                               ? this.created_by.toJSON()                           : null,

      external_employee_signature:   this.external_employee_signature              ? this.external_employee_signature                   : null,
      customer_signature:            this.customer_signature                       ? this.customer_signature                            : null,
      signer:                        this.signer                                   ? this.signer                                        : null,

      attachment_url:                this.attachment_url                           ? this.attachment_url                                : null,
      attachment_rotation_angle:     this.attachment_rotation_angle                ? this.attachment_rotation_angle                     : null,
      submit_with_timestamp:         this.submit_with_timestamp                    ? this.submit_with_timestamp                         : null,

      mileage_money_report_id:       this.mileage_money_report_id                  ? this.mileage_money_report_id                       : null,

      external_employee_notes:       this.external_employee_notes                  ? this.external_employee_notes                       : null,
      internal_employee_notes:       this.internal_employee_notes                  ? this.internal_employee_notes                       : null,
      customer_notes:                this.customer_notes                           ? this.customer_notes                                : null,

      customer_review:               this.customer_review                          ? this.customer_review                               : null,
      customer_reviewed_at:          this.customer_reviewed_at                     ? this.customer_reviewed_at.toISOString()            : null,
      reviewed_by_customer:          this.reviewed_by_customer                     ? this.reviewed_by_customer.toJSON()                 : null,

      checked_time_at:               this.checked_time_at                          ? this.checked_time_at.toISOString()                 : null,
      checked_time_by:               this.checked_time_by                          ? this.checked_time_by.toJSON()                      : null,

      approved_at:                   this.approved_at                              ? this.approved_at.toISOString()                     : null,
      approved_by:                   this.approved_by                              ? this.approved_by.toJSON()                          : null,

      deleted_by:                    this.deleted_by                               ? this.deleted_by.toJSON()                           : null,
      archived_at:                   this.archived_at                              ? this.archived_at.toISOString()                     : null,
      days_before_auto_archive:      this.daysBeforeAutoArchive !== null           ? this.daysBeforeAutoArchive                         : null,

      life_state:                    this.life_state                               ? this.life_state                                    : null,
      export_state:                  this.export_state                             ? this.export_state                                  : null,
      export_status:                 this.export_status !== undefined              ? this.export_status                                 : null,
      export_comment:                this.export_comment                           ? this.export_comment                                : null,

      split_child_id:                this.split_child_id                           ? this.split_child_id                                : null,
      split_parent_id:               this.split_parent_id                          ? this.split_parent_id                               : null,

      original_end_date:             this.original_end_date                        ? this.original_end_date.toISOString()               : null,
      belongs_to_internal_locations: this.belongsToInternalLocations !== undefined ? this.belongsToInternalLocations                    : null,

      original_time_frames:          this.original_time_frames?.length             ? this.original_time_frames                          : [],
      corrected_time_frames:         this.corrected_time_frames?.length            ? this.corrected_time_frames                         : [],
      time_frames:                   this.time_frames?.length                      ? this.time_frames.map(tf => tf.toJSON())            : [],

      customer_notifications:        this.customer_notifications                   ? this.customer_notifications.map(cn => cn.toJSON()) : null,
      state_iso:                     this.state_iso                                ? this.state_iso                                     : "",
      recognizedTimeFrames:          this.recognizedTimeFrames?.length             ? this.recognizedTimeFrames.map(tf => tf.toJSON())   : null,
      ocrUsed:                       this.ocrUsed                                  ? this.ocrUsed                                       : null,
    });
  }

}

export class ActivityReportSimplified extends ActivityReportBasic {
  created_at:                 Date;
  approved_at:                Date;
  archived_at:                Date;

  customer_review:            string;
  customer_reviewed_at:       Date;
  customer_released_at:       Date;
  customer_rejected_at:       Date;
  reviewed_by_customer_id:    number;

  export_status:              number;
  export_comment:             string;

  life_state:                 string;
  export_state:               string;

  split_child_id:             number;
  split_parent_id:            number;

  mileage_money_report_id:    number;
  with_attachment:            boolean;
  checked_time_at:            Date;
  checked_time_by:            Employee;
  holidays:                   any[];

  belongsToInternalLocations: boolean;
  constructor(data: ActivityReportSimplifiedModel | ActivityReportSimplified) {
    super(data);
    this.created_at              = this.parceDate(data.created_at);
    this.approved_at             = this.parceDate(data.approved_at);
    this.archived_at             = this.parceDate(data.archived_at);

    this.customer_review         = data.customer_review;
    this.customer_reviewed_at    = this.parceDate(data.customer_reviewed_at);
    this.customer_released_at    = data.customer_review === 'released' && this.parceDate(data.customer_reviewed_at);
    this.customer_rejected_at    = data.customer_review === 'rejected' && this.parceDate(data.customer_reviewed_at);
    this.reviewed_by_customer_id = data.reviewed_by_customer_id;

    this.export_status           = data.export_status  ? +data.export_status : null;
    this.export_comment          = data.export_comment ? data.export_comment : null;

    this.life_state              = data.life_state     ? data.life_state     : null;
    this.export_state            = data.export_state   ? data.export_state   : null;

    this.split_child_id          = data.split_child_id;
    this.split_parent_id         = data.split_parent_id;

    this.mileage_money_report_id = data.mileage_money_report_id ? +data.mileage_money_report_id : null;
    this.with_attachment         = data.with_attachment         ? data.with_attachment          : null;
    this.checked_time_at         = this.parceDate(data.checked_time_at);
    this.checked_time_by         = super.checkIfInstance(data.checked_time_by, Employee);
    this.holidays                = data.holidays && data.holidays.length ? data.holidays : [];

    this.belongsToInternalLocations = (data as ActivityReportSimplifiedModel).belongs_to_internal_locations;
  }

  toJSON(): ActivityReportSimplifiedModel {
    return Object.assign(super.toJSON(), {
      created_at:                    this.created_at                 ? this.created_at.toISOString()           : null,
      approved_at:                   this.approved_at                ? this.approved_at.toISOString()          : null,
      archived_at:                   this.archived_at                ? this.archived_at.toISOString()          : null,

      customer_review:               this.customer_review            ? this.customer_review                    : null,
      customer_reviewed_at:          this.customer_reviewed_at       ? this.customer_reviewed_at.toISOString() : null,
      reviewed_by_customer_id:       this.reviewed_by_customer_id    ? this.reviewed_by_customer_id            : null,

      export_status:                 this.export_status              ? this.export_status                      : null,
      export_comment:                this.export_comment             ? this.export_comment                     : null,

      life_state:                    this.life_state                 ? this.life_state                         : null,
      export_state:                  this.export_state               ? this.export_state                       : null,

      split_child_id:                this.split_child_id             ? this.split_child_id                     : null,
      split_parent_id:               this.split_parent_id            ? this.split_parent_id                    : null,

      mileage_money_report_id:       this.mileage_money_report_id    ? this.mileage_money_report_id            : null,
      with_attachment:               this.with_attachment            ? this.with_attachment                    : null,
      checked_time_at:               this.checked_time_at            ? this.checked_time_at.toISOString()      : null,
      checked_time_by:               this.checked_time_by            ? this.checked_time_by.toJSON()           : null,
      holidays:                      this.holidays                   ? this.holidays                           : null,

      belongs_to_internal_locations: this.belongsToInternalLocations ? this.belongsToInternalLocations         : null
    });
  }
}

export class RecognizedTimeFrame {
  id:               number;
  imageSize:        number[];
  calendarWeek:     number;

  date:             Date;
  startedTime:      Date;
  endedTime:        Date;
  pause:            number;
  worked:           number;

  calendarWeekMeta: RecognizedTimeFrameMeta;
  dateMeta:         RecognizedTimeFrameMeta;
  startedTimeMeta:  RecognizedTimeFrameMeta;
  endedTimeMeta:    RecognizedTimeFrameMeta;
  pauseMeta:        RecognizedTimeFrameMeta;
  workedMeta:       RecognizedTimeFrameMeta;

  metaTable:        RecognizedTimeFrameMeta[];

  constructor(data: RecognizedTimeFrameModel) {
    this.id               = data.id                 ? data.id                                                    : null;
    this.imageSize        = data.image_size         ? data.image_size                                            : null;
    this.calendarWeek     = data.calendar_week      ? +data.calendar_week                                        : null;

    this.date             = this.isConfidentData(data, 'date')         ? this.isValid(this.parceDate(data.date))                    : null;
    this.startedTime      = this.isConfidentData(data, 'started_time') ? this.isValid(this.parceTime(this.date, data.started_time)) : null;
    this.endedTime        = this.isConfidentData(data, 'ended_time')   ? this.isValid(this.parceTime(this.date, data.ended_time))   : null;
    this.pause            = this.isConfidentData(data, 'pause')        ? this.getTimeStringDuration(data.pause)                     : null;
    this.worked           = this.isConfidentData(data, 'worked')       ? this.getStringDuration(data.worked)                        : null;

    // if (!this.startedTime && this.endedTime   && this.worked) this.startedTime = new Date(this.endedTime.getTime()   - this.worked - this.worked);
    // if (!this.endedTime   && this.startedTime && this.worked) this.endedTime   = new Date(this.startedTime.getTime() + this.worked + this.pause);

    this.calendarWeekMeta = this.newRecognizedTimeFrameMetaHandler(data, 'calendar_week', this.date);
    this.dateMeta         = this.newRecognizedTimeFrameMetaHandler(data, 'date',          this.date);
    this.startedTimeMeta  = this.newRecognizedTimeFrameMetaHandler(data, 'started_time',  this.date);
    this.endedTimeMeta    = this.newRecognizedTimeFrameMetaHandler(data, 'ended_time',    this.date);
    this.pauseMeta        = this.newRecognizedTimeFrameMetaHandler(data, 'pause',         this.date);
    this.workedMeta       = this.newRecognizedTimeFrameMetaHandler(data, 'worked',        this.date);
  }

  toJSON(): RecognizedTimeFrameModel {
    return {
      id:                 this.id               ? this.id                                  : null,
      image_size:         this.imageSize        ? this.imageSize                           : null,
      calendar_week:      this.calendarWeek     ? this.calendarWeek+''                     : null,

      date:               this.date             ? this.date.toISOString()                  : null,
      started_time:       this.startedTime      ? this.toTimeString(this.startedTime)      : null,
      ended_time:         this.endedTime        ? this.toTimeString(this.endedTime)        : null,
      pause:              this.pause            ? this.parceNumberToDateString(this.pause) : null,
      worked:             this.worked           ? this.parceNumberToString(this.worked)    : null,

      calendar_week_meta: this.calendarWeekMeta ? this.calendarWeekMeta                    : null,
      date_meta:          this.dateMeta         ? this.dateMeta                            : null,
      started_time_meta:  this.startedTimeMeta  ? this.startedTimeMeta                     : null,
      ended_time_meta:    this.endedTimeMeta    ? this.endedTimeMeta                       : null,
      pause_meta:         this.pauseMeta        ? this.pauseMeta                           : null,
      worked_meta:        this.workedMeta       ? this.workedMeta                          : null,
    }
  }

  private newRecognizedTimeFrameMetaHandler(data: RecognizedTimeFrameModel, metaField: string, frameDate: Date): RecognizedTimeFrameMeta {
    return data[metaField+'_meta'] ? new RecognizedTimeFrameMeta(data, metaField, frameDate) : null;
  }

  private isConfidentData(data: RecognizedTimeFrameModel, field: string): any {
    return data[field] && data[`${field}_meta`] && data[`${field}_meta`].confidence > 0.75;
  }

  private isValid(date: Date): Date {
    if (!date)                              return null;
    if (date.toString() === 'Invalid Date') return null;
    if (isNaN(date.getTime()))              return null;
    if (date.getFullYear() === 1970)        return null;
    return date;
  }

  private parceNumberToDateString(dateNumber: number): string {
    let fullMins = dateNumber / 1000 / 60;
    let hours = Math.floor(fullMins / 60) ;
    let mins  = fullMins - (hours * 60);
    return `${hours}: ${mins}`;
  }

  private parceNumberToString(dateNumber: number): string {
    return this.parceNumberToDateString(dateNumber).replace(':', '');
  }

  private getStringDuration(string: string): number {
    let mins, hours, date = string.split('');
    if (date.length === 4) hours = `${date[0]}${date[1]}`;
    else hours = `${date[0]}`;
    mins = `${date[date.length-2]}${date[date.length-1]}`;
    return this.getTimeStringDuration(`${hours}:${mins}`);
  }

  private getTimeStringDuration(timeString): number {
    let { hours, mins } = this.parceTimeString(timeString);
    return ((hours * 60) + mins) * 60 * 1000;
  }

  private parceTimeString(timeString: string): { hours: number, mins: number } {
    let time  = timeString.split(':');
    let hours = +time[0];
    let mins  = +time[1];

    return { hours, mins };
  }

  private parceTime(date: Date, timeString: string): Date {
    let { hours, mins } = this.parceTimeString(timeString);
    return date && new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, mins, 0);
  }

  private parceDate(date: Date | string | number): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  private toTimeString(date: Date): string {
    return `${date.getHours()}:${date.getMinutes()}`;
  }

}

export class RecognizedTimeFrameMeta {
  metaField:  string;
  frameDate:  Date;
  content:    string;
  confidence: number;
  polygon:    number[];
  constructor(data: RecognizedTimeFrameModel, metaField: string, frameDate: Date) {
    this.metaField  = metaField;
    this.frameDate  = frameDate;
    this.content    = data[metaField+'_meta']?.content                ? data[metaField+'_meta'].content        : null;
    this.confidence = data[metaField+'_meta']?.confidence ? frameDate ? data[metaField+'_meta'].confidence : 0 : null;
    this.polygon    = data[metaField+'_meta']?.polygon                ? data[metaField+'_meta'].polygon        : null;
  }
}
