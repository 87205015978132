import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TimeFramesResponseModel } from '@shared/models';
import { TimeFrame               } from '@shared/factories';

import { SessionStorageService } from '../session-storage.service';
import { QueryCollectorService } from '../query-collector.service';

import { environment } from 'environments/environment';

@Injectable()
export class TimeFramesService {
  private get TN_TIME_FRAMES_API(): string { return `${environment.apiUrl}time_tracking/api/v3/time_frames` };

  constructor (
    private http:                  HttpClient,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService
  ) { }

  requestTimeFramesByDates(params: any): Observable<TimeFrame[]> {
    return this.http.get<TimeFramesResponseModel>(this.TN_TIME_FRAMES_API + this.queryCollectorService.getTimeFramesQuery(params)).pipe(
      map(res => {
        this.sessionStorageService.changeTotalCount(res.meta.paging.total_count);
        return res.time_frames;
      }),
      map(res => res.map(r => new TimeFrame(r)))
    );
  }

}
