import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomersResponseModel, EmployeeModel } from '@shared/models';
import { Customer, CustomerEmailListItem       } from '@shared/factories';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private get TN_CUSTOMERS_API(): string { return `${environment.apiUrl}time_tracking/api/v3/customers` };

  constructor( private http: HttpClient ) { }

  private requestCustomers(assignmentId: number): Observable<EmployeeModel[]> {
    return this.http.get<CustomersResponseModel>(`${this.TN_CUSTOMERS_API}?q[assignments][id]=${assignmentId}&q[customers][locked]=false`).pipe(
      map((res: any) => res.customers)
    );
  }

  getCustomers(assignmentId: number): Observable<Customer[]> {
    return this.requestCustomers(assignmentId).pipe(
      map((res: EmployeeModel[]) => res.map(c => new Customer(c)))
    );
  }

  getCustomersEmeils(assignmentId: number): Observable<CustomerEmailListItem[]> {
    return this.requestCustomers(assignmentId).pipe(
      map((res: EmployeeModel[]) => res.map(c => new CustomerEmailListItem(c)))
    );
  }

}
