import { Injectable } from '@angular/core';
import { Router     } from '@angular/router';
import { Observable } from 'rxjs';

import { ActivityReportsService } from './time-tracking/activity-reports.service';
import { PhotoDocumentService   } from './time-tracking/photo-document.service';
import { VacationRequestListService } from './time-tracking/vacation-request-list.service';
import { SessionStorageService  } from './session-storage.service';
import { NotificationService    } from './notification.service';

import {
  ActivityReportSimplified,
  PhotoDocumentSimplified,
  VacationRequest
} from '@shared/factories';
import { FastNavigationModel } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class FastNavigationService {
  constructor(
    private router:                     Router,
    private activityReportsService:     ActivityReportsService,
    private photoDocumentService:       PhotoDocumentService,
    private vacationRequestListService: VacationRequestListService,
    private sessionStorageService:      SessionStorageService,
    private notificationService:        NotificationService
  ) { }

  loadPreloadedData(): Observable<ActivityReportSimplified[] | PhotoDocumentSimplified[] | VacationRequest[]> {
    switch (this.getUseCase()) {
      case 'wp-details':
        return this.activityReportsService.getWorkingPeriods(this.getFromLocation());
      case 'pd-details':
        return this.photoDocumentService.getPhotoDocuments(this.getFromLocation());
      case 'vr-details':
        return this.vacationRequestListService.getVacationRequests(this.getFromLocation());
      default:
        break;
    }
  }

  goNext(entry: FastNavigationModel): void {
    this.notificationService.wait();
    let path: any = {
      route: [`/time-tracking/${this.getUseCase()}/${entry.id}`],
      query: { state: { data: window.history.state.data } }
    };
    this.router.navigate(path.route, path.query);
    if (entry.page) this.sessionStorageService.changePageNumber(entry.page);
  }

  private getUseCase(): string {
    if (this.router.url.includes('wp-details')) return 'wp-details';
    if (this.router.url.includes('pd-details')) return 'pd-details';
    if (this.router.url.includes('vr-details')) return 'vr-details';
  }

  private getFromLocation(): string {
    let fromRoute = (this.sessionStorageService.redirectUrl || '').replace('/time-tracking/', '').split('?')[0];
    switch (fromRoute) {
      case 'search-archiv':
        return 'archive';
      case 'failed-erp':
        return 'failed-erp';
      case 'list':
      case 'pd-list':
      case 'vr-list':
      default:
        return 'dashboard';
    }
  }

}
