import { MileageMoneyDatesModel } from "@shared/models";

export class MileageMoneyDates {
  starts_at: Date;
  ends_at:   Date;
  constructor(data: MileageMoneyDatesModel) {
    this.starts_at = this.parceDate(data.starts_at);
    this.ends_at   = this.parceDate(data.ends_at);
  }

  toJSON(): MileageMoneyDatesModel {
    return ({
      starts_at: this.starts_at ? this.parceDateISO(this.starts_at) : null,
      ends_at:   this.ends_at   ? this.parceDateISO(this.ends_at)   : null
    });
  }

  private parceDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  private parceDateISO(date): string {
    return date && date instanceof Date ? date.toISOString() : null;
  }

}
