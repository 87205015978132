import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { take, tap } from 'rxjs/operators';

import { FastNavigationService, NotificationService, SessionStorageService } from '@shared/services';
import { FastNavigationModel } from '@shared/models';
@Component({
  selector:      'fast-navigation',
  templateUrl: './fast-navigation.component.html',
  host: { class: 'am-flex-justify-end gap50 font-icon color-blue b-grey-mobile gap0-mobile mb5-mobile' }
})
export class FastNavigationComponent implements OnChanges {
  @Input() id: number;
  @Output() saveFastNavigationItems = new EventEmitter<any>();

  preloadedData: FastNavigationModel[];
  prevEl:        FastNavigationModel;
  nextEl:        FastNavigationModel;
  constructor(
    private fastNavigationService: FastNavigationService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.id?.firstChange || changes?.id?.currentValue !== changes?.id?.previousValue) this.reloadFastNavigation();
  }

  changeEntry(el: FastNavigationModel): void {
    this.fastNavigationService.goNext(el);
  }

  private reloadFastNavigation(): void {
    this.preloadedData = this.preloadedData || window?.history?.state?.data || [];

    let originIndex = this.preloadedData.findIndex(i => i.id === this.id);
    this.prevEl = null;
    this.nextEl = null;
    if (originIndex !== -1) {
      if (originIndex > 0)                             this.prevEl = this.preloadedData[originIndex - 1];
      if (originIndex < this.preloadedData.length - 1) this.nextEl = this.preloadedData[originIndex + 1];
    };

    if (originIndex !== -1 && (!this.prevEl || !this.nextEl)) this.requestElementsForFastNavigation();
    else this.notificationService.close();

    this.saveFastNavigationItems.emit({
      prevEl: this.prevEl,
      nextEl: this.nextEl
    });
  }

  private requestElementsForFastNavigation(): void {
    let tempPage       = this.sessionStorageService.pageNumberValue;
    let tempTotalPages = this.sessionStorageService.pagesTotal;
    let requestPage;

    if (tempTotalPages > 1) {
      if      (!this.prevEl && tempPage       !== 1)        requestPage = tempPage - 1;
      else if (!this.nextEl && tempTotalPages !== tempPage) requestPage = tempPage + 1;

      if (requestPage) {
        this.sessionStorageService.changePageNumber(requestPage);
        this.fastNavigationService.loadPreloadedData().pipe(
          take(1),
          tap(() => this.sessionStorageService.changePageNumber(tempPage))
        ).subscribe(
          (list: any[]) => {
            if (list.length) {
              let newEntries = list.map(ar => ({
                id: ar.id,
                page: requestPage
              }));
              if (requestPage > tempPage) this.preloadedData = [...this.preloadedData, ...newEntries];
              if (requestPage < tempPage) this.preloadedData = [...newEntries, ...this.preloadedData];
              window.history.state.data = this.preloadedData;
              this.reloadFastNavigation();
            }
          }
        );
      } else this.notificationService.close();
    }
  }

}
