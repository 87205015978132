import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExternalEmployeeExtendedModel, ExternalsResponse, ExternalsResponseModel } from '@shared/models';
import { ExternalEmployeeExtended } from '@shared/factories';

import { QueryCollectorService } from '../query-collector.service';

import { environment } from 'environments/environment';

@Injectable()
export class ExternalEmployeesService {
  private get TN_EXTERNAL_EMLOYEES_API(): string { return `${environment.apiUrl}time_tracking/api/v3/external_employees` };
  constructor (
    private http:                  HttpClient,
    private queryCollectorService: QueryCollectorService
  ) { }

  private requestExternals(query: string = ''): Observable<ExternalEmployeeExtendedModel[]> {
    return this.http.get<any>(`${this.TN_EXTERNAL_EMLOYEES_API}${query}`)
    .pipe(
      map(res => res?.external_employees || [])
    );
  }

  loadExternals(page: number = 1, search: string = '', filters: any = {}): Observable<ExternalEmployeeExtended[]> {
    return this.loadExternalsPaginated(page, search, filters).pipe(
      map(res => res.externals)
    );
  }

  loadExternalsPaginated(page: number = 1, search: string = '', filters: any = {}, archive: boolean = false): Observable<ExternalsResponse> {
    return this.http.get<ExternalsResponseModel>(`${this.TN_EXTERNAL_EMLOYEES_API}${archive ? '/archive': ''}${this.queryCollectorService.getExternalsPaginated(page, search, filters)}`)
    .pipe(
      map(res => ({
        totalPages: res?.meta?.paging?.total_pages || 0,
        externals:  res?.external_employees?.map(ext => new ExternalEmployeeExtended(ext)) || []
      }))
    );
  }

  loadExtendedExternals(filters: any = {}): Observable<ExternalEmployeeExtended[]> {
    return this.requestExternals(this.queryCollectorService.getExternalsForVacationRequestsQuery(filters)).pipe(
      map((res: ExternalEmployeeExtendedModel[]) => res.map(ext => new ExternalEmployeeExtended(ext)))
    );
  }

}
