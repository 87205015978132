import { Component, Input, EventEmitter, Output } from '@angular/core';
import { formatDate           } from '@angular/common';
import { switchMap, take, tap } from 'rxjs/operators';
import { Observable, of       } from 'rxjs';

import { TableHeaderEntryModel, TableBodyEntryModel, TableBodyCellEntryModel } from '@shared/models';
import { InvoicesService, NotificationService, InvoicesCountersService       } from '@shared/services';
import { Invoice, InvoiceExtended } from '@shared/factories';

@Component({
  selector:     'invoices-table',
  templateUrl:  '../../table-entries.component.html',
  styleUrls: ['./invoices-table.component.sass'],
})
export class InvoicesTableComponent {
  @Input() rows:         Invoice[];
  @Input() paginationId: string;

  @Input() filter:       string;
  @Output() headerCallback = new EventEmitter<void>();
  constructor(
    private invoicesService:         InvoicesService,
    private invoicesCountersService: InvoicesCountersService,
    private notificationService:     NotificationService
  ) { }

  headerCallbackHandler(res): void {
    this.headerCallback.emit(res);
  }

  getTableClass(): string {
    return 'invoices-table';
  }

  getOptionalValue(field: string): boolean {
    return this[field] || null;
  }

  prepareTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: 'Dokumenttyp',    class: 'doc-type-cell',      sort_by: ['doc_type_id']                        },
      { title: 'Betreff',        class: 'subject-cell',       sort_by: ['subject']                            },
      { title: 'Rechnungsdatum', class: 'invoice-date-cell',  sort_by: ['invoice_date']                       },
      { title: 'Archiviert',     class: 'archived-at-cell',   sort_by: ['archived_at'], pageOnly: ['archive'] },
      { title: 'Archiviert von', class: 'archived-by-cell',                             pageOnly: ['archive'] },
      { title: 'Aktion',         class: `action-cell ${this.filter === 'archive' ? 'archive' : ''}`           },
    ].filter(h => h.pageOnly ? h.pageOnly.find(p => p === this.filter) : true);
  }

  prepareTableBody(): TableBodyEntryModel[] {
    return this.rows.map((i: Invoice): TableBodyEntryModel => ({
      bold: !i.customerReadAt,
      cells: [
        { xs_label: 'Dokumenttyp',    class: 'doc-type-cell word-break-all', value: i.doc_type_name                                                                              },
        { xs_label: 'Betreff',        class: 'subject-cell word-break-all',  value: i.subject                                                                                    },
        { xs_label: 'Rechnungsdatum', class: 'invoice-date-cell',            value: i.invoice_date ? formatDate(i.invoice_date, 'dd.MM.yyyy', 'de') : '-'                        },
        { xs_label: 'Archiviert',     class: 'archived-at-cell',             value: i.archived_at  ? formatDate(i.archived_at,  'dd.MM.yyyy', 'de') : '-', pageOnly: ['archive'] },
        { xs_label: 'Archiviert von', class: 'archived-by-cell',             value: i.archived_by  ? i.archived_by.name                             : '-', pageOnly: ['archive'] },
        { xs_label: ' ',              class: `action-cell ${this.filter === 'archive' ? 'archive' : ''}`,
          buttons: [
            {
              class: 'am-flex-basis-auto width-40 btn white font-small-icon tooltip-hover',
              icon: `${i.archived_at ? 'icon-rotate-ccw'  : 'icon-archive'} am-flex-center`,
              tooltip: i.archived_at ? 'Wiederherstellen' : 'Archivieren',
              click: () => i.archived_at ? this.manualUnArchive(i) : this.manualArchive(i)
            },
            {
              class: 'am-flex-basis-auto width-40 btn white font-small-icon tooltip-hover icon-save',
              click: () => this.saveInvoice(i),
              tooltip: 'Speichern'
            },
            {
              class: 'action-cell-button',
              label: 'Ansehen',
              click: () => this.openPrint(i)
            }
          ]
        }
      ].filter((c: TableBodyCellEntryModel) => c.pageOnly && c.pageOnly.filter(p => p).length ? c.pageOnly.filter(p => p).find(p => p === this.filter) : true)
    }));
  }

  private manualArchive(invoice: Invoice): void {
    this.notificationService.confirm(
      'Bestätigung',
      'Möchten Sie dieses Dokument wirklich archivieren?',
      () => {
        this.notificationService.wait();
        this.invoicesService.archiveInvoice(invoice.id).pipe(
          take(1),
          switchMap(res => this.readInvoice(res))
        ).subscribe(
          res => this.refreshInvoices(),
          err => this.notificationService.alert(err)
        );
      }
    );
  }

  private manualUnArchive(invoice: Invoice): void {
    this.notificationService.confirm(
      'Bestätigung',
      'Möchten Sie dieses Dokument wirklich wiederherstellen?',
      () => {
        this.notificationService.wait();
        this.invoicesService.unArchiveInvoice(invoice.id).pipe(
          take(1),
          switchMap(res => this.readInvoice(res))
        ).subscribe(
          res => this.refreshInvoices(),
          err => this.notificationService.alert(err)
        );
      }
    );
  }

  private refreshInvoices(): void {
    this.invoicesService.forceReload();
    this.invoicesCountersService.reloadCounters();
  }

  private openPrint(invoice: Invoice): void {
    this.notificationService.wait();
    this.invoicesService.requestInvoiceById(invoice.id).pipe(
      take(1),
      tap(res => {
        let blob = this.base64ToBlob(res.base64pdf.split('base64,')[1]);
        let newWindow = window.open('about:blank');
        newWindow.document.write(`<iframe src="${URL.createObjectURL(blob)}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`)
        setTimeout(() => {
          newWindow.document.title = invoice.filename;
          newWindow.document.body.style.margin = '0';
        });
      }),
      switchMap(res => this.readInvoice(res))
      ).subscribe(
      res => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  private saveInvoice(invoice: Invoice): void {
    this.notificationService.wait();
    this.invoicesService.requestInvoiceById(invoice.id).pipe(
      take(1),
      tap(res => {
        var a = document.createElement("a");
        a.href = res.base64pdf;
        a.download = this.getFileNameWIthExtension(invoice.filename);
        a.click();
      }),
      switchMap(res => this.readInvoice(res))
    ).subscribe(res => this.notificationService.close());
  }

  private readInvoice(invoice: InvoiceExtended): Observable<InvoiceExtended> {
    if (!invoice.customerReadAt) return this.invoicesService.readInvoice(invoice.id).pipe(
      tap(res => {
        let index = this.rows.findIndex(r => r.id === invoice.id);
        if (index >= 0) this.rows[index].customerReadAt = res.customerReadAt;
      })
    );
    return of(null);
  }

  private getFileNameWIthExtension(fileName): string {
    if (fileName.toLowerCase().includes('.pdf')) return fileName;
    else return fileName + '.pdf';
  }

  private base64ToBlob(base64): Blob {
    let raw;
    raw = window.atob(base64);
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for(let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return new Blob([array], {type: 'application/pdf'});
  }

}
