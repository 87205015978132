import { MileageMoneyDailyModel } from "@shared/models";

export class MileageMoneyDaily {
  id:                        number;
  mileage_money_id:          number;
  mileage_money_report_id:   number;
  time_frame_id:             number;

  deleted:                   boolean;

  created_at:                Date;
  updated_at:                Date;

  started_at:                Date;
  ended_at:                  Date;

  amount_of_km:              number;
  total_amount_of_km:        string;
  amount_per_km:             string;
  assignment_address:        string;
  external_employee_address: string;

  total_sum:                 string;
  calculated_sum:            string;
  constructor(data: MileageMoneyDailyModel, deleted: boolean = false) {
    this.id                        = data.id                        ? data.id                                    : null;
    this.mileage_money_id          = data.mileage_money_id          ? data.mileage_money_id                      : null;
    this.mileage_money_report_id   = data.mileage_money_report_id   ? data.mileage_money_report_id               : null;
    this.time_frame_id             = data.time_frame_id             ? data.time_frame_id                         : null;

    this.deleted                   = deleted;

    this.created_at                = this.parceDate(data.created_at);
    this.updated_at                = this.parceDate(data.updated_at);

    this.started_at                = this.parceDate(data.started_at);
    this.ended_at                  = this.parceDate(data.ended_at);

    this.amount_of_km              = +data.amount_of_km || null;
    this.total_amount_of_km        = this.parseToPayFormat(this.amount_of_km * 2, 1);
    this.amount_per_km             = data.amount_per_km             ? data.amount_per_km                         : null;
    this.assignment_address        = data.assignment_address        ? data.assignment_address                    : null;
    this.external_employee_address = data.external_employee_address ? data.external_employee_address             : null;

    this.total_sum                 = +data.total_sum > 0            ? this.parseToPayFormat(data.total_sum)      : null;
    this.calculated_sum            = data.calculated_sum            ? this.parseToPayFormat(data.calculated_sum) : null;
  }

  toSubmitJSON() {
    return {
      id:                        this.id,
      amount_of_km:              this.total_amount_of_km ? (+(this.total_amount_of_km.replace(',','.')) / 2) : null,
      amount_per_km:             this.amount_per_km + '',
      assignment_address:        this.assignment_address,
      external_employee_address: this.external_employee_address,
      total_sum:                 this.total_sum ? this.total_sum.replace(',','.') : '0.0'
    };
  }

  toJSON(): MileageMoneyDailyModel {
    return {
      id:                        this.id                        ? this.id                              : null,
      mileage_money_id:          this.mileage_money_id          ? this.mileage_money_id                : null,
      mileage_money_report_id:   this.mileage_money_report_id   ? this.mileage_money_report_id         : null,
      time_frame_id:             this.time_frame_id             ? this.time_frame_id                   : null,

      created_at:                this.created_at                ? this.created_at.toISOString()        : null,
      updated_at:                this.updated_at                ? this.updated_at.toISOString()        : null,

      started_at:                this.started_at                ? this.started_at.toISOString()        : null,
      ended_at:                  this.ended_at                  ? this.ended_at.toISOString()          : null,

      amount_of_km:              this.amount_of_km              ? this.amount_of_km + ''               : null,
      amount_per_km:             this.amount_per_km             ? this.amount_per_km                   : null,
      assignment_address:        this.assignment_address        ? this.assignment_address              : null,
      external_employee_address: this.external_employee_address ? this.external_employee_address       : null,

      total_sum:                 this.total_sum                 ? this.total_sum.replace(',','.')      : null,
      calculated_sum:            this.calculated_sum            ? this.calculated_sum.replace(',','.') : null
    };
  }

  private parseToPayFormat(val, to?): string {
    if (!val || !isNaN(val)) return this.parseNumberToString(+val, to);
    else if (typeof val === 'string') return this.parseNumberToString(parseFloat(val.split(",").join(".")), to);
  }

  private parseNumberToString(val, to: number = 2): string {
    return val.toFixed(to).replace('.', ',');
  }

  private parceDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }
}
