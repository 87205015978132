import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, Subject, timer         } from 'rxjs';
import { map, switchMap, takeUntil, tap, repeat } from 'rxjs/operators';

import { QueryCollectorService } from '../query-collector.service';
import { SessionStorageService } from '../session-storage.service';

import { VacationRequest          } from '@shared/factories';
import { VacationRequestsResponce } from '@shared/models';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VacationRequestListService {
  private start$ = new Subject<void>();
  private stop$  = new Subject<void>();

  private get VR_IMPORTS_API():   string { return `${environment.apiUrl}time_tracking/api/v3/vacation_requests_exports/dashboard` };
  private get VR_API():           string { return `${environment.apiUrl}time_tracking/api/v3/vacation_requests`                   };
  private get VR_DASHBOARD_API(): string { return `${this.VR_API}/dashboard`                                                      };
  private get VR_ARCHIVE_API():   string { return `${this.VR_API}/archive`                                                        };

  constructor (
    private http:                  HttpClient,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService
  ) { }

  forceReload(): void {
    this.forceStop();
    this.start$.next();
  }

  forceStop(): void {
    this.stop$.next();
  }

  get vacationRequests(): Observable<VacationRequest[]> {
    return timer(0).pipe(
      switchMap(() => this.requestVacationRequests('dashboard')),
      takeUntil(this.stop$),
      repeat({ delay: () => this.start$ })
    );
  }

  getVacationRequests(vrType: string): Observable<VacationRequest[]> {
    return this.requestVacationRequests(vrType);
  }

  getVacationRequestsArchive(): Observable<VacationRequest[]> {
    return this.requestVacationRequests('archive');
  }

  private requestVacationRequests(vrType: string): Observable<VacationRequest[]> {
    return this.http.get<VacationRequestsResponce>(this.getVacationRequestsUrl(vrType))
    .pipe(
      tap(res => {
        this.sessionStorageService.changeTotalCount(res?.meta?.paging?.total_count || 0);
        this.sessionStorageService.changeTotalPages(res?.meta?.paging?.total_pages || 0);
      }),
      map(res => res?.vacation_requests?.map(item => new VacationRequest(item)) || [])
    );
  }

  private getVacationRequestsUrl(vrType: string): string {
    if      (vrType === 'dashboard')  return this.VR_DASHBOARD_API + this.queryCollectorService.getVacationRequestsQuery();
    if      (vrType === 'failed-erp') return this.VR_IMPORTS_API   + this.queryCollectorService.getFailedERPsQuery('vacation_requests');
    else if (vrType === 'archive')    return this.VR_ARCHIVE_API   + this.queryCollectorService.getVacationRequestsArchiveQuery();
  }

  getVacationRequestTypes(filter: string = null): Observable<any> {
    return of({
      totalPages: 1,
      'vacation-types': [
        { label: 'Tarifurlaub',  value: 'annual'   },
        { label: 'Sonderurlaub', value: 'special'  },
        { label: 'AZK',          value: 'overtime' }
      ].filter(t => filter ? t.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) : true)
    });
  }

  getVacationRequestReasons(type: string = '', filter: string = null): Observable<any> {
    return of({
      totalPages: 1,
      'vacation-reasons': this.getVacationReasonByType(type)
      .filter(t => filter ? t.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) : true)
    });
  }

  private getVacationReasonByType(type: string = null) {
    switch (type) {
      case 'special':
        return [
          { label: 'Hochzeit oder Geburt',                             value: 'wedding_birth'            },
          { label: 'Tod von nahen Verwandten',                         value: 'death_of_close_relatives' },
          { label: 'Tod von Verwandten',                               value: 'death_of_relatives'       }
        ];
      case 'overtime':
        return [
          { label: 'Veranlassung durch Arbeitgeber (SB / Disponent)',  value: 'internal'                 },
          { label: 'Veranlassung durch Arbeitnehmer (MA)',             value: 'external'                 }
        ];
      default:
        return [...this.getVacationReasonByType('special'), ...this.getVacationReasonByType('overtime')];
    }
    
  }

}
