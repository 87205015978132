import { AssignmentBasicModel, AssignmentModel, AssignmentExtendedModel } from "@shared/models";
import { ExternalEmployee, EBSListItem } from "@shared/factories";

export class AssignmentBasic {
  id:            number;
  title:         string;
  project:       string;
  prefilProject: boolean;
  constructor(data: AssignmentBasicModel) {
    this.id            = data.id      ? data.id      : null;
    this.title         = data.title   ? data.title   : null;
    this.project       = data.project ? data.project : null;
    this.prefilProject = data.prefill_activity_with_project !== null ? data.prefill_activity_with_project : null;
  }

  toJSON(): AssignmentBasicModel {
    return {
      id:      this.id      ? this.id      : null,
      title:   this.title   ? this.title   : null,
      project: this.project ? this.project : null,
      prefill_activity_with_project: this.prefilProject !== null ? this.prefilProject : null,
    };
  }

}

export class Assignment extends AssignmentBasic {
  active:            boolean;
  confirmed:         boolean;

  created_at:        Date;
  starts_at:         Date;
  ends_at:           Date;

  equal_pay:         Date;
  max_rental_period: Date;
  constructor(data: AssignmentModel) {
    super(data);
    this.active            = data.active    !== undefined ? data.active    : null;
    this.confirmed         = data.confirmed !== undefined ? data.confirmed : null;

    this.created_at        = this.parceDate(data.created_at);
    this.starts_at         = this.parceDate(data.starts_at);
    this.ends_at           = this.parceDate(data.ends_at);

    this.equal_pay         = this.parceDate(data.equal_pay);
    this.max_rental_period = this.parceDate(data.max_rental_period);
  }

  get expiringSoon(): boolean {
    return (this.ends_at.getTime() - new Date().getTime()) < (10 * 24 * 60 * 60 * 1000);
  }

  private parceDate(date): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): AssignmentModel {
    return Object.assign(super.toJSON(), {
      active:    this.active    !== undefined && this.active    !== null ? this.active    : null,
      confirmed: this.confirmed !== undefined && this.confirmed !== null ? this.confirmed : null,

      created_at:        this.created_at        ? this.created_at.toISOString()           : null,
      starts_at:         this.starts_at         ? this.starts_at.toISOString()            : null,
      ends_at:           this.ends_at           ? this.ends_at.toISOString()              : null,

      equal_pay:         this.equal_pay         ? this.equal_pay.toISOString()            : null,
      max_rental_period: this.max_rental_period ? this.max_rental_period.toISOString()    : null
    });
  }
}

export class AssignmentExtended extends Assignment {
  personal_number:   number;
  customer_location: number;
  external_employee: ExternalEmployee;
  ebs_data_list:     EBSListItem[];

  stateISO:          string;
  constructor(data: AssignmentExtendedModel) {
    super(data);
    this.personal_number   = data.personal_number       ? +data.personal_number                           : null;
    this.customer_location = data.customer_location     ? +data.customer_location                         : null;
    this.external_employee = data.external_employee     ? new ExternalEmployee(data.external_employee)    : null;
    this.ebs_data_list     = data.ebs_data_list?.length ? data.ebs_data_list.map(e => new EBSListItem(e)) : [];
    this.stateISO          = data.state_iso;
  }

  get latestEBS(): EBSListItem {
    return this.ebs_data_list.find(e => e.version === Math.max(...this.ebs_data_list.map(ee => ee.version)));
  }

  get history(): EBSListItem[] {
    return this.ebs_data_list.filter(e => e.id !== this.latestEBS.id);
  }

  toJSON(): AssignmentExtendedModel {
    return Object.assign(super.toJSON(), {
      personal_number:   this.personal_number       ? this.personal_number                    : null,
      customer_location: this.customer_location     ? this.customer_location                  : null,
      external_employee: this.external_employee     ? this.external_employee.toJSON()         : null,
      ebs_data_list:     this.ebs_data_list?.length ? this.ebs_data_list.map(e => e.toJSON()) : [],
      state_iso:         this.stateISO              ? this.stateISO                           : null,
    });
  }
}
 