import { MileageMoneyReportModel, MileageMoneyDailyModel, LicensePlateModel } from "@shared/models";
import { MileageMoneyDaily } from "@shared/factories";

export class MileageMoneyReport {
  id:                    number;
  working_period_id:     number;

  calculated_sum:        string;
  custom_calculated_sum: string;

  license_plate:         LicensePlateModel;
  license_plate_mapped:  string;

  reviewer_note:         string;
  report_items:          MileageMoneyReportItem[];

  created_at:            Date;
  updated_at:            Date;
  constructor(data: MileageMoneyReportModel) {
    this.id                    = data.id                         ? data.id                                                               : null;
    this.working_period_id     = data.working_period_id          ? data.working_period_id                                                : null;

    this.calculated_sum        = data.calculated_sum             ? data.calculated_sum                                                   : null;
    this.custom_calculated_sum = +data.custom_calculated_sum > 0 ? this.parseToPayFormat(data.custom_calculated_sum)                     : null;

    this.license_plate         = data.license_plate              ? data.license_plate                                                    : null;
    this.license_plate_mapped  = data.license_plate              ? `${data.license_plate?.country} ${this.license_plate?.license_plate}` : '-';

    this.reviewer_note         = data.reviewer_note              ? data.reviewer_note                                                    : null;
    this.report_items          = data.report_items?.length       ? this.collectMileageMoneyReports(data.report_items)                    : [];

    this.created_at            = this.parceDate(data.created_at);
    this.updated_at            = this.parceDate(data.updated_at);
  }

  get mileageMoneyTotalKm(): string {
    return this.report_items.reduce((sum: number, val: MileageMoneyReportItem) => {
      return this.sumFloatNumbers(sum, +(val.mileageReportMoneyTotalKm.replace(',','.')));
    }, 0).toFixed(1).replace('.', ',');
  }
  
  get mileageMoneyTotalSum(): string {
    return this.report_items.reduce((sum: number, val: MileageMoneyReportItem) => {
      return this.sumFloatNumbers(sum, +(val.mileageReportMoneyTotalSum.replace(',','.')));
    }, 0).toFixed(2).replace('.', ',');
  }

  private sumFloatNumbers(a: number, b: number): number {
    return ((+a * 10) + (+b * 10)) / 10;
  }

  toSubmitJSON() {
    return {
      custom_calculated_sum: this.custom_calculated_sum !== null && this.custom_calculated_sum !== undefined ? this.custom_calculated_sum.replace(',', '.') : null,
      report_items:          this.report_items && this.report_items.length ? this.report_items.map(r => r.report_days).reduce((sum, arr) => sum.concat(arr), []).map(r => r.toSubmitJSON()) : [],
      reviewer_note:         this.reviewer_note
    };
  }

  toJSON(): MileageMoneyReportModel {
    return {
      id:                    this.id                    ? this.id                                      : null,
      working_period_id:     this.working_period_id     ? this.working_period_id                       : null,

      calculated_sum:        this.calculated_sum        ? this.calculated_sum.replace(',', '.')        : null,
      custom_calculated_sum: this.custom_calculated_sum ? this.custom_calculated_sum.replace(',', '.') : null,

      license_plate:         this.license_plate         ? this.license_plate                           : null,
      reviewer_note:         this.reviewer_note         ? this.reviewer_note                           : null,
      report_items:          this.report_items.map(r => r.report_days).reduce((sum, arr) => sum.concat(arr), []).map(r => r.toJSON()),

      created_at:            this.created_at            ? this.created_at.toISOString()                : null,
      updated_at:            this.updated_at            ? this.updated_at.toISOString()                : null,
    };
  }

  private parseToPayFormat(val, to?): string {
    if (!val || !isNaN(val)) return this.parseNumberToString(+val, to);
    else if (typeof val === 'string') return this.parseNumberToString(parseFloat(val.split(",").join(".")), to);
  }

  private parseNumberToString(val, to: number = 2): string {
    return val.toFixed(to).replace('.', ',');
  }

  private parceDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  private collectMileageMoneyReports(reports: MileageMoneyDailyModel[]): MileageMoneyReportItem[] {
    return Object.values(reports.reduce((obj: any, report: MileageMoneyDailyModel) => {
      if (!obj[report.mileage_money_id]) obj[report.mileage_money_id] = [];
      obj[report.mileage_money_id].push(report);
      return obj;
    }, {})).map(reports => new MileageMoneyReportItem({
      mileage_money_id: reports[0].mileage_money_id,
      report_items: reports
    }))
    .sort((a, b) => a.activeMileageMoney[0].started_at.getTime() - 
                    b.activeMileageMoney[0].started_at.getTime()
    );
  }

}

export class MileageMoneyReportItem {
  mileage_money_id: number;
  report_days:      MileageMoneyDaily[];
  constructor(data: any) {
    this.mileage_money_id = data.mileage_money_id;
    this.report_days      = data.report_items.map(i => new MileageMoneyDaily(i));
  }

  get activeMileageMoney(): MileageMoneyDaily[] {
    return this.report_days.filter(m => !m.deleted);
  }

  get mileageReportMoneyTotalKm(): string {
    return this.activeMileageMoney.reduce((sum: number, val: MileageMoneyDaily) => {
      return this.sumFloatNumbers(sum, +(val.total_amount_of_km.replace(',','.')));
    }, 0).toFixed(1).replace('.', ',');
  }

  get mileageReportMoneyTotalSum(): string {
    return this.activeMileageMoney.reduce((sum: number, val: MileageMoneyDaily) => {
      return this.sumFloatNumbers(sum, +(val.calculated_sum.replace(',','.')));
    }, 0).toFixed(2).replace('.', ',');
  }

  private sumFloatNumbers(a: number, b: number): number {
    return ((+a * 10) + (+b * 10)) / 10;
  }
}
