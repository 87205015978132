import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocumentTypeModel, DocumentTypeResponceModel } from '@shared/models';
import { QueryCollectorService } from '../query-collector.service';

import { environment } from 'environments/environment';

@Injectable()
export class DocumentTypesService {
  private get TN_DOCUMENT_TYPES_API(): string { return `${environment.apiUrl}time_tracking/api/v2/document_types` };
  documentTypeCache$: Observable<DocumentTypeModel[]>;

  constructor (
    private http:                  HttpClient,
    private queryCollectorService: QueryCollectorService
  ) { }

  get documentTypes(): Observable<DocumentTypeModel[]> {
    if (!this.documentTypeCache$) this.documentTypeCache$ = this.requestDocumentTypes(this.TN_DOCUMENT_TYPES_API);
    return this.documentTypeCache$;
  }

  private requestDocumentTypes(url: string): Observable<DocumentTypeModel[]> {
    return this.http.get<DocumentTypeResponceModel>(url).pipe(
      map(res => res.document_types)
    );
  }

  requestDocumentTypesPagination(page: number = 1, search: string = ''): any {
    return this.http.get<any>(`${this.TN_DOCUMENT_TYPES_API}${this.queryCollectorService.getDocumentTypesPaginated(page, search)}`).pipe(
      map(res => ({
        totalPages: res.meta.total_pages,
        documents: res.document_types
      }))
    );
  }

}
