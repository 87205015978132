import { Component, Input, EventEmitter, Output } from '@angular/core';
import { take } from 'rxjs/operators';

import { CustomersCompaniesService, NotificationService } from '@shared/services';
import { TableHeaderEntryModel, TableBodyEntryModel } from '@shared/models';
import { CustomerCompanyExtended } from '@shared/factories';

@Component({
  selector:     'companies-table',
  templateUrl:  '../../table-entries.component.html',
  styleUrls: ['./companies-table.component.sass'],
})
export class CompaniesTableComponent {
  @Input() rows:         CustomerCompanyExtended[];
  @Input() paginationId: string;

  @Output() headerCallback = new EventEmitter<any>();
  constructor(
    private customersCompaniesService: CustomersCompaniesService,
    private notificationService:       NotificationService
  ) { }

  headerCallbackHandler(res): void {
    this.headerCallback.emit(res);
  }

  getTableClass(): string {
    return 'companies-table';
  }

  getOptionalValue(field: string): boolean {
    return this[field] || null;
  }

  prepareTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: 'Name',                   class: 'name-cell',                  sort_by: ['name']           },
      { title: 'Kunden-Nr.',             class: 'company-num-cell',           sort_by: ['company_number'] },
      { title: 'Adresse',                class: 'address-cell',               sort_by: ['address']        },
      { title: 'eBeleg zvoove',          class: 'am-flex-center pdf-cell'                                 },
      { title: 'Voreinstellung Projekt', class: 'am-flex-center project-cell'                             }      
    ];
  }

  prepareTableBody(): TableBodyEntryModel[] {
    return this.rows.map((company: CustomerCompanyExtended, index: number): TableBodyEntryModel => ({
      cells: [
        { xs_label: 'Name',            class: 'name-cell',        value: company.name                                       },
        { xs_label: 'Kunden-Nr.',      class: 'company-num-cell', value: company.company_number                             },
        { xs_label: 'Adresse',         class: 'address-cell',     value: company.address                                    },
        { xs_label: 'eBeleg zvoove',   class: 'am-flex-align gap10 pdf-cell', children: [
          { value: 'Echtzeit', class: 'hover-active-blue', click: () => this.togglePDFFormat(company, 'real', index) },
          { toggler: {
              init: company.pdfTimeFormat,
              left: 'real',
              right: 'industrial',
              click: (val) => this.togglePDFFormat(company, val, index)
            }
          },
          { value: 'Industriezeit', class: 'hover-active-blue', click: () => this.togglePDFFormat(company, 'industrial', index) },
        ]},
        { xs_label: 'Voreinstellung Projekt',   class: 'am-flex-align gap10 project-cell', children: [
          { value: 'Aus', class: 'hover-active-blue', click: () => this.toggleAssignmentProjectPrefill(company, 0, index) },
          { toggler: {
              init: +company.prefillProject,
              left:  0,
              right: 1,
              click: (val) => this.toggleAssignmentProjectPrefill(company, val, index)
            }
          },
          { value: 'An', class: 'hover-active-blue', click: () => this.toggleAssignmentProjectPrefill(company, 1, index) },
        ]}
      ]
    }));
  }

  private togglePDFFormat(entry: CustomerCompanyExtended, format: string, index: number): void {
    if (entry.pdfTimeFormat !== format) this.notificationService.confirm(
      'Sind Sie sicher ?',
      'Möchten Sie den Standard für den eBeleg wirklich ändern? Ihre Änderungen haben sofortigen Einfluss.',
      () => this.customersCompaniesService.updateCompanyPDFFormat(entry.id, format).pipe(take(1)).subscribe(
        res => {
          this.toggleToggler(index, 'pdf-cell');
          setTimeout(() => entry.pdfTimeFormat = format, 300);
        },
        error => this.notificationService.alert(error.message || error)
      )
    );
  }

  private toggleAssignmentProjectPrefill(entry: CustomerCompanyExtended, prefill: number, index: number): void {
    if (+entry.prefillProject !== prefill) this.notificationService.confirm(
      'Sind Sie sicher ?',
      'Möchten Sie den Standard für die Projektbelegung bei TN Erstellung wirklich ändern? Ihre Änderungen haben sofortigen Einfluss.',
      () => this.customersCompaniesService.updateCompanyProject(entry.id, +prefill).pipe(take(1)).subscribe(
        res => {
          this.toggleToggler(index, 'project-cell');
          setTimeout(() => entry.prefillProject = !!prefill, 300);
        },
        error => this.notificationService.alert(error.message || error)
      )
    );
  }

  private toggleToggler(index: number, cell: string): void {
    let el = document.getElementsByClassName('list-row'); 
    if (el && el.length && el[index]) {
      el = el[index].getElementsByClassName(cell);
      if (el && el.length && el[0]) el[0].getElementsByClassName('toggler')[0].classList.toggle('right');
    }
  }

  private call(num: string): void {
    let tel;
    if (num) {
      if (num.charAt(0) === '0') tel = `+49${num.substring(1)}`;
      else if (num.substring(0,2) === '49' && num.length === 12) tel = `+${num}`;
      else tel = `+49${num}`;
    } else return this.notificationService.alert('Die Telefonnummer des External Mitarbeiters ist nicht vorhanden.');

    let link = `tel:${tel}`;
    window.open(link, '_system');
  }

}
