import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map        } from 'rxjs/operators';

import { QueryCollectorService, SessionStorageService, UserService } from '@shared/services';
import { Holiday } from '@shared/factories';

import { environment } from 'environments/environment';

interface HolidayMappedISO extends Holiday {
  states:       string[];
  statesString: string;
}

@Injectable()
export class HolidaysService {
  private get TN_HOLIDAYS(): string { return `${environment.apiUrl}time_tracking/api/v3/holidays` };

  holidaysList: Holiday[];
  constructor (
    private http:                  HttpClient,
    private userService:           UserService,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService,
  ) { }

  get holidays(): Holiday[] {
    return this.holidaysList;
  }

  setHolidays(list: Holiday[]): void {
    this.holidaysList = list;
  }

  getHolidaysWithParams(start: Date, end: Date, state: string = null): Holiday[] {
    let s = new Date(start);
    let e = new Date(end);
    s.setHours(0,0,0,0);
    e.setHours(23,59,59);
    return this.holidaysList.filter(h => h.state_iso === state && h.date.getTime() >= s.getTime() && h.date.getTime() <= e.getTime());
  }

  loadHolidays(from: Date = null, to: Date = null): Observable<Holiday[]> {
    return this.http.get<any>(this.TN_HOLIDAYS + this.queryCollectorService.getHolidaysTimesQuery(from, to)).pipe(
      map(res => res.holidays),
      map(holidays => holidays.map(h => new Holiday(h)))
    );
  }

  loadHolidaysByActivePills(from: Date = null, to: Date = null, location: number = null): Observable<HolidayMappedISO[]> {
    return this.loadHolidays(from, to).pipe(
      map(holidays => {
        let temp          = {};
        let userLocations = this.userService.currentUserValue.locations;
        let pills         = this.sessionStorageService.activePills;

        let activeLocations;
        if (location) activeLocations = userLocations.filter(ul => location === ul.identifier);
        else          activeLocations = userLocations.filter(ul => !pills.length || pills.find(p => p.location === ul.identifier));

        if (!activeLocations.length && location) return [];
        if ( activeLocations.length) holidays = holidays.filter(h => !h.state_iso || activeLocations.find(al => al.state_iso === h.state_iso));

        holidays.forEach(h => {
          let global = !h.state_iso;

          if (!temp[h.name]) temp[h.name] = h;

          if      (!temp[h.name].states)                                     temp[h.name].states = h.state_iso ? [h.state_iso] : ['global'];
          else if ( temp[h.name].states.find(s => s === 'global') || global) temp[h.name].states = ['global'];
          else                                                               temp[h.name].states.push(h.state_iso);
        });

        for (const key in temp) {
          temp[key].statesString = temp[key].states.filter(s => s && s !== 'global').join(',');
          temp[key].statesString = temp[key].statesString?.length ? `(${temp[key].statesString})` : '';
        }

        return Object.values(temp);
      })
    );
  }

}
