// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .activity-report-mileage-table .holiday-cell {
  order: 1;
  flex: 0 0 30px;
}
::ng-deep .activity-report-mileage-table .day-cell {
  order: 2;
  flex: 1 0 80px;
}
::ng-deep .activity-report-mileage-table .from-cell {
  min-width: 125px;
  order: 3;
  flex: 2 1 200px;
}
::ng-deep .activity-report-mileage-table .to-cell {
  min-width: 160px;
  order: 4;
  flex: 2 1 200px;
}
::ng-deep .activity-report-mileage-table .total-km-cell {
  min-width: 60px;
  order: 5;
  flex: 1 1 100px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
