import { formatDate } from "@angular/common";
import { CustomerNotificationModel, HolidayModel, VacationRequestBasicModel, VacationRequestExtendedModel, VacationRequestModel } from "@shared/models";
import { Assignment, Customer, CustomerNotification, Employee, ExternalEmployee, InternalEmployee, Weeks } from "@shared/factories";

export class VacationRequestBasic {
  id:                  number;
  createdAt:           Date;
  createdBy:           Employee;

  leaveType:           string;
  leaveTypeMapped:     string;

  reason:              string;
  reasonMapped:        string;

  startsOn:            Date;
  endsOn:              Date;
  calendarWeek:        string;
  date:                string;

  totalVacationDays:   number;
  workingVacationDays: number;
  constructor(data: VacationRequestBasicModel) {
    this.id                  = data.id         ? data.id                       : null;
    this.createdBy           = data.created_by ? new Employee(data.created_by) : null;
    this.createdAt           = this.parceDate(data.created_at);

    this.leaveType           = data.leave_type ? data.leave_type               : null;
    this.reason              = data.reason     ? data.reason                   : null;

    this.leaveTypeMapped     = this.mapLeaveType(data.leave_type);
    this.reasonMapped        = this.mapReason(data.reason);

    this.startsOn            = this.parceDate(data.starts_on);
    this.endsOn              = this.parceDate(data.ends_on);
    this.calendarWeek        = `KW ${Weeks.getWeekNumber(this.startsOn) > 52 ? '01': Weeks.getWeekNumber(this.startsOn)}`;
    this.date                = `${formatDate(this.startsOn, 'dd.MM.yyyy','de')} - ${formatDate(this.endsOn, 'dd.MM.yyyy', 'de')}`;

    this.totalVacationDays   = Math.round((this.endsOn.getTime() - this.startsOn.getTime()) / (24*60*60*1000))+1;
    this.workingVacationDays = this.getWorkingVacationDays(this.startsOn, this.endsOn);
  }

  parceDate(date): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  private getWorkingVacationDays(from: Date, to: Date): number {
    let count = 0;
    for (let start = new Date(from); start.getTime() <= to.getTime(); start.setDate(start.getDate()+1)) { 
      if (start.getDay() > 0 && start.getDay() < 6) count++;
    }
    return count;
  }

  private mapLeaveType(type: string): string {
    switch (type) {
      case 'annual':
        return 'Tarifurlaub';
      case 'special':
        return 'Sonderurlaub';
      case 'unpaid':
        return 'Unbezahlt';
      case 'overtime':
        return 'AZK';
      default:
        return 'N/A';
    }
  }

  private mapReason(reason: string): string {
    switch (reason) {
      case 'wedding_birth':
        return 'Hochzeit oder Geburt';
      case 'wedding':
        return 'Hochzeit';
      case 'death':
        return 'Todesfall';
      case 'death_of_close_relatives':
        return 'Tod von nahen Verwandten';
      case 'death_of_relatives':
        return 'Tod von Verwandten';
      case 'birth':
        return 'Geburt';
      case 'other':
        return 'Sonstiges';
      default:
        return null;
    }
  }

  toJSON(): VacationRequestBasicModel {
    return ({
      id:         this.id        ? this.id                      : null,
      created_at: this.createdAt ? this.createdAt.toISOString() : null,
      created_by: this.createdBy ? this.createdBy.toJSON()      : null,

      starts_on:  this.startsOn  ? this.startsOn.toISOString()  : null,
      ends_on:    this.endsOn    ? this.endsOn.toISOString()    : null,
      leave_type: this.leaveType ? this.leaveType               : null,
      reason:     this.reason    ? this.reason                  : null,
    });
  }

}

export class VacationRequest extends VacationRequestBasic {
  assignment:              Assignment;
  holidays:                HolidayModel[];
  signer:                  string;

  externalEmployee:        ExternalEmployee;
  nameReverse:             string;

  externalReview:          string;
  externalReviewedAt:      Date;

  creationInitiator:       string;
  initiatorMapped:         string;

  customerReview:          string;
  customerReviewedAt:      Date;
  customerRejectionReason: string;
  customerReviewComment:   string;

  precheckedByInternalAt:  Date;
  internalReviewedAt:      Date;
  internalReview:          string;
  internalRejectionReason: string;
  internalReviewComment:   string;

  archivedAt:              Date;
  daysBeforeAutoArchive:   number;
  autoArchiveAt:           Date;
  archivedBySystem:        boolean;

  state:                   string;
  exportState:             string;
  exportComment:           string;

  createdByInternal:       boolean;
  awaitingExternalReview:  boolean;

  readOnlyForCustomer:     boolean;
  readOnlyForInternal:     boolean;
  constructor(data: VacationRequestModel) {
    super(data);

    this.assignment              = data.assignment                ? new Assignment(data.assignment)              : null;
    this.holidays                = data.holidays                  ? data.holidays                                : null;
    this.signer                  = data.signer                    ? data.signer                                  : null;

    this.externalEmployee        = data.external_employee         ? new ExternalEmployee(data.external_employee) : null;
    this.nameReverse             = data.external_employee?.last_name  + ', ' + data.external_employee?.first_name;

    this.creationInitiator       = data.creation_initiator        ? data.creation_initiator                      : null;
    this.initiatorMapped         = this.mapInitiator(data.creation_initiator);

    this.externalReview          = data.external_review           ? data.external_review                         : null;
    this.externalReviewedAt      = super.parceDate(data.external_reviewed_at);

    this.customerReview          = data.customer_review           ? data.customer_review                         : null;
    this.customerReviewedAt      = super.parceDate(data.customer_reviewed_at);
    this.customerRejectionReason = data.customer_rejection_reason ? data.customer_rejection_reason               : null;
    this.customerReviewComment   = data.customer_review_comment   ? data.customer_review_comment                 : null;

    this.precheckedByInternalAt  = super.parceDate(data.prechecked_by_internal_at);
    this.internalReview          = data.internal_review           ? data.internal_review                         : null;
    this.internalReviewedAt      = super.parceDate(data.internal_reviewed_at);
    this.internalRejectionReason = data.internal_rejection_reason ? data.internal_rejection_reason               : null;
    this.internalReviewComment   = data.internal_review_comment   ? data.internal_review_comment                 : null;

    this.archivedAt              = super.parceDate(data.archived_by_system_at);
    this.daysBeforeAutoArchive   = data.days_before_auto_archive;
    this.autoArchiveAt           = this.parceAutoArchiveAt();
    this.archivedBySystem        = data.state === 'archived_by_system';

    this.state                   = data.state                     ? data.state                                   : null;
    this.exportState             = data.export_state              ? data.export_state                            : null;
    this.exportComment           = data.export_comment            ? data.export_comment                          : null;

    this.createdByInternal       = this.createdBy?.userGroup === 'Internal Employee';
    this.awaitingExternalReview  = this.state === 'awaiting_external_approval';

    this.readOnlyForCustomer     = !!this.archivedAt || this.createdByInternal || !this.assignment || !!this.customerReview || !!this.internalReviewedAt;
    this.readOnlyForInternal     = !!this.archivedAt || !!this.internalReviewedAt;
  }

  private parceAutoArchiveAt(): Date {
    if (this.daysBeforeAutoArchive === null) return null;
    if (isNaN(this.daysBeforeAutoArchive))   return null;
    let date = new Date();
    date.setDate(date.getDate() + this.daysBeforeAutoArchive);
    date.setHours(0,0,0);
    return date;
  }

  private mapInitiator(initiator: string): string {
    switch (initiator) {
      case 'internal':
        return 'Veranlassung durch Arbeitgeber (SB / Disponent)';
      case 'external':
        return 'Veranlassung durch Arbeitnehmer (MA)';
    }
  }

  toJSON(): VacationRequestModel {
    return Object.assign(super.toJSON(), {
      assignment:                this.assignment              ? this.assignment.toJSON()                  : null,
      holidays:                  this.holidays                ? this.holidays                             : null,
      signer:                    this.signer                  ? this.signer                               : null,
      external_employee:         this.externalEmployee        ? this.externalEmployee.toJSON()            : null,

      creation_initiator:        this.creationInitiator       ? this.creationInitiator                    : null,

      external_review:           this.externalReview          ? this.externalReview                       : null,
      external_reviewed_at:      this.externalReviewedAt      ? this.externalReviewedAt.toISOString()     : null,

      customer_review:           this.customerReview          ? this.customerReview                       : null,
      customer_reviewed_at:      this.customerReviewedAt      ? this.customerReviewedAt.toISOString()     : null,
      customer_review_comment:   this.customerReviewComment   ? this.customerReviewComment                : null,
      customer_rejection_reason: this.customerRejectionReason ? this.customerRejectionReason              : null,

      prechecked_by_internal_at: this.precheckedByInternalAt  ? this.precheckedByInternalAt.toISOString() : null,
      internal_review:           this.internalReview          ? this.internalReview                       : null,
      internal_reviewed_at:      this.internalReviewedAt      ? this.internalReviewedAt.toISOString()     : null,
      internal_rejection_reason: this.internalRejectionReason ? this.internalRejectionReason              : null,
      internal_review_comment:   this.internalReviewComment   ? this.internalReviewComment                : null,

      archived_by_system_at:     this.archivedAt              ? this.archivedAt.toISOString()             : null,
      days_before_auto_archive:  this.daysBeforeAutoArchive   ? this.daysBeforeAutoArchive                : null,

      state:                     this.state                   ? this.state                                : null,
      export_state:              this.exportState             ? this.exportState                          : null,
      export_comment:            this.exportComment           ? this.exportComment                        : null,
    });
  }

}

export class VacationRequestExtended extends VacationRequest {
  involvedAssignments:        Assignment[];
  customerSignatureUrl:       string;

  reviewedByExternal:         ExternalEmployee;
  reviewedByCustomer:         Customer;
  reviewedByInternal:         InternalEmployee;
  precheckedByInternal:       InternalEmployee;

  failedErpReport:            boolean;
  belongsToInternalLocations: boolean;

  approvedByExternal:         boolean;
  rejectedByExternal:         boolean;

  customerNotifications:      CustomerNotification[];
  constructor(data: VacationRequestExtendedModel, notifications: CustomerNotificationModel[] = null) {
    super(data)

    this.involvedAssignments        = data.involved_assignments?.length  ? data.involved_assignments.map(a => new Assignment(a)) : null;
    this.customerSignatureUrl       = data.customer_signature_url        ? data.customer_signature_url                           : null;

    this.reviewedByExternal         = data.reviewed_by_external          ? new ExternalEmployee(data.reviewed_by_external)       : null;
    this.reviewedByCustomer         = data.reviewed_by_customer          ? new Customer(data.reviewed_by_customer)               : null;
    this.reviewedByInternal         = data.reviewed_by_internal          ? new InternalEmployee(data.reviewed_by_internal)       : null;
    this.precheckedByInternal       = data.prechecked_by_internal        ? new InternalEmployee(data.prechecked_by_internal)     : null;

    this.belongsToInternalLocations = data.belongs_to_internal_locations ? data.belongs_to_internal_locations                    : null;
    this.customerNotifications      = notifications ? notifications.map(n => new CustomerNotification(n)).sort((a,b) => b.created_at.getTime() - a.created_at.getTime()) : null;

    this.failedErpReport            = this.belongsToInternalLocations && this.internalReview && this.exportState === 'failed_export';

    this.approvedByExternal         = data.external_review === 'approved';
    this.rejectedByExternal         = data.external_review === 'rejected';

    this.readOnlyForInternal        = this.readOnlyForInternal  || !this.belongsToInternalLocations;
  }

  toJSON(): VacationRequestModel {
    return Object.assign(super.toJSON(), {
      involved_assignments:          this.involvedAssignments        ? this.involvedAssignments.map(a => a.toJSON()) : null,
      holidays:                      this.holidays                   ? this.holidays                                 : null,

      customer_signature_url:        this.customerSignatureUrl       ? this.customerSignatureUrl                     : null,

      reviewed_by_external:          this.reviewedByExternal         ? this.reviewedByExternal.toJSON()              : null,
      reviewed_by_customer:          this.reviewedByCustomer         ? this.reviewedByCustomer.toJSON()              : null,
      reviewed_by_internal:          this.reviewedByInternal         ? this.reviewedByInternal.toJSON()              : null,
      prechecked_by_internal:        this.precheckedByInternal       ? this.precheckedByInternal.toJSON()            : null,

      belongs_to_internal_locations: this.belongsToInternalLocations ? this.belongsToInternalLocations               : null,
    });
  }

}
